const Globals = {
  auth0Client: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000',
  sentryKey: process.env.REACT_APP_SENTRY_KEY || '',
  sentryApp: process.env.REACT_APP_SENTRY_APP || '',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  developmentMode: process.env.NODE_ENV || 'production'
};

export default Globals;
