import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { Modal } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery'
import './IncidentImagesModal.css'

class IncidentImagesModal extends Component {
  constructor (props) {
    super(props)

    this.hide = this.hide.bind(this)
    this.renderShareAndRotate = this.renderShareAndRotate.bind(this)
    this.rotateImage = this.rotateImage.bind(this)
    this.addImageRef = this.addImageRef.bind(this)

    this.images = []
  }

  hide () {
    const { dispatch } = this.props

    dispatch(hideModal())
  }

  addImageRef (image) {
    if (image && this.images.findIndex(i => i.src === image.src) === -1) {
      this.images.push(image)
    }
  }

  rotateImage (currentIndex, images, isForward = true) {
    const imgArray = this.props.images
    const currentElement = images[currentIndex]
    const currentImage = imgArray[imgArray.findIndex(image => image.url === currentElement.src)]

    currentImage.currentRotation = currentImage.currentRotation || 0

    let newRotation = isForward
      ? currentImage.currentRotation + 90
      : currentImage.currentRotation - 90

    if (newRotation === 360 || newRotation === -360) newRotation = 0

    currentImage.currentRotation = newRotation
    currentElement.style.transform = `rotate(${newRotation}deg)`
  }

  renderShareAndRotate () {
    const { images } = this.props

    if (!this._imageGallery) return null

    const currentIndex = this._imageGallery.getCurrentIndex()
    const shareBody = `mailto:?subject=Sharing: Incident Image?body=${images[currentIndex].url}`

    return (
      <div className='image-gallery-custom-controls'>
        <a href={shareBody} title='share' id='image-gallery-share-image'>
          <i className='material-icons'>share</i>
        </a>
        <button id='image-gallery-rotate-image' onClick={this.rotateImage.bind(this, currentIndex, this.images, false)}>
          <i className='material-icons'>rotate_left</i>
        </button>
        <button id='image-gallery-rotate-image' onClick={this.rotateImage.bind(this, currentIndex, this.images)}>
          <i className='material-icons'>rotate_right</i>
        </button>
      </div>
    )
  }

  render () {
    const { visible, images } = this.props

    const transformedPhotosArray = images.map((image) => {
      return {
        'original': image.url,
        'thumbnail': image.thumbnail_url,
        'height': image.height,
        'width': image.width
      }
    })

    const renderItem = (item) => {
      return (
        <div className='image-gallery-image'>
          <img
            ref={el => { this.addImageRef(el) }}
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            title={item.originalTitle}
            onLoad={this.props.onImageLoad}
          />
          {
            item.description &&
              <span className='image-gallery-description'>
                {item.description}
              </span>
          }
        </div>
      )
    }

    return (
      <Modal show={visible} onHide={this.hide} id='chat-modal' bsSize='large' backdrop>
        <Modal.Header id='image-header' closeButton>
          <Modal.Title>
            <i className='material-icons'>image</i>
            <span className='image-text'>Images</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='chat-modal-body'>
          <ImageGallery
            ref={i => { this._imageGallery = i }}
            items={transformedPhotosArray}
            slideInterval={5000}
            showPlayButton={false}
            renderItem={renderItem}
            renderCustomControls={this.renderShareAndRotate}
            showIndex
          />
        </Modal.Body>
      </Modal>
    )
  }
}

export default connect()(IncidentImagesModal)
