import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

export const REQUEST_PREDEFINED_MESSAGES = 'REQUEST_PREDEFINED_MESSAGES' 
export const RECEIVE_PREDEFINED_MESSAGES = 'RECEIVE_PREDEFINED_MESSAGES'
export const REQUEST_PREDEFINED_MESSAGES_FAILURE = 'REQUEST_PREDEFINED_MESSAGES_FAILURE'

export const requestGetPredefinedMessages = (reportId) => {
  return {
    type: REQUEST_PREDEFINED_MESSAGES,
    reportId
  }
}
export const receiveGetPredefinedMessages = (json) => {
    return {
    type: RECEIVE_PREDEFINED_MESSAGES,
    detailsList: json,
    receivedAt: Date.now()
  }
}

export const fetchGetPredefinedMessages = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_PREDEFINED_MESSAGES_FAILURE,
    error
  }
}

export const getPredefinedMessages = (reportId) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)
    // console.log('request-options',requestOptions)
    dispatch(requestGetPredefinedMessages(reportId))
// XT THIS IS PULLING IN ALL THE AGENCY JSON AND THE REPORT SCHEMA AT THIS ENDPOINT!!!
    return fetch(`${Globals.apiEndpoint}/dashboard/report/${reportId}/predefined_messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveGetPredefinedMessages(json))
        }
      })
      .catch(error => {
        dispatch(fetchGetPredefinedMessages(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}