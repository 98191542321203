/* globals localStorage */
import React, { Component } from "react";
import Navbar from "../../views/Navbar/Navbar";
import AuthService from "../../utils/AuthService";
import Globals from "../../utils/globals";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import store from "../../store";
import { setProfile } from "../../actions/profile";

import * as actionCreators from "../../actions/actionCreators";
import Main from "../Main/Main";
import "./App.css";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

const auth = new AuthService(Globals.auth0Client, Globals.auth0Domain);
// dynamic year - carter foi - 3/28/18
var d = new Date();
var year = d.getFullYear();

class App extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    const { profile } = store.getState();
    console.log(
      " in the app component at the profile. profile in STATE is: ",
      profile
    );
    if (!profile.details.email) {
      const lsProfile = JSON.parse(localStorage.getItem("profile"));
      console.log(
        " in the app component at !profile.details.email - checking lsprofile existance, lsprofile is: ",
        lsProfile
      );
      if (lsProfile) dispatch(setProfile(lsProfile));
    } else
      console.log(
        "in App.js at setting up profile from lsprofile.. something went wrong"
      );
  }
  render() {
    return (
      <div className="App">
        <Navbar auth={auth} />
        <Main auth={auth} class="App-content" />
        <footer>
          <div>
            The Brass Star Group, LLC: CONFIDENTIAL-COMPANY PROPRIETARY do not
            disclose without written approval.
          </div>
          <div>
            &copy; {year} The Brass Star Group, LLC. All Rights Reserved.
          </div>
        </footer>
      </div>
    );
  }
}

export default connect(mapDispatchToProps)(App);
