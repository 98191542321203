/* globals fetch, localStorage */
import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

// Fetch
export const REQUEST_INTERNAL_MESSAGES = 'REQUEST_INTERNAL_MESSAGES'
export const RECEIVE_INTERNAL_MESSAGES = 'RECEIVE_INTERNAL_MESSAGES'
export const REQUEST_INTERNAL_MESSAGES_FAILURE = 'REQUEST_INTERNAL_MESSAGES_FAILURE'
// Pending Messages
export const UPDATE_PENDING_INTERNAL_MESSAGE = 'UPDATE_PENDING_INTERNAL_MESSAGE'
export const CLEAR_PENDING_INTERNAL_MESSAGE = 'CLEAR_PENDING_INTERNAL_MESSAGE'
// Add Messages
export const ADD_INTERNAL_MESSAGE = 'ADD_INTERNAL_MESSAGE'
export const ACK_ADD_INTERNAL_MESSAGE = 'ACK_ADD_INTERNAL_MESSAGE'
export const ADD_INTERNAL_MESSAGE_FAILURE = 'ADD_INTERNAL_MESSAGE_FAILURE'
// Clear
export const CLEAR_INTERNAL_MESSAGES = 'CLEAR_INTERNAL_MESSAGES'

export const requestInternalMessages = (incidentId, agencyId, reportSchemaId) => {
  return {
    type: REQUEST_INTERNAL_MESSAGES,
    incidentId,
    agencyId,
    reportSchemaId
  }
}

export const receiveInternalMessages = (json) => {
  return {
    type: RECEIVE_INTERNAL_MESSAGES,
    internalMessages: json,
    receivedAt: Date.now()
  }
}

export const clearMessages = () => {
  return {
    type: CLEAR_INTERNAL_MESSAGES
  }
}

export const requestInternalMessagesFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_INTERNAL_MESSAGES_FAILURE,
    error
  }
}

export const fetchInternalMessages = (incidentId, agencyId, reportSchemaId) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)

    dispatch(requestInternalMessages(incidentId, agencyId, reportSchemaId))

    return fetch(`${Globals.apiEndpoint}/dashboard/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/internal_messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveInternalMessages(json))
        }
      })
      .catch(error => {
        dispatch(requestInternalMessagesFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}

export const addInternalMessage = (incidentId, agencyId, reportSchemaId, message) => {
  return {
    type: ADD_INTERNAL_MESSAGE,
    incidentId,
    agencyId,
    reportSchemaId,
    message
  }
}

export const postInternalMessageFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: ADD_INTERNAL_MESSAGE_FAILURE,
    error
  }
}

export const ackPostInternalMessage = (json) => {
  return {
    type: ACK_ADD_INTERNAL_MESSAGE,
    result: json,
    receivedAt: Date.now()
  }
}

export const postInternalMessage = (incidentId, agencyId, reportSchemaId, message) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const defaulRequestOptions = getRequestOptions(token)
    const requestOptions = {
      method: 'POST',
      headers: {
        ...defaulRequestOptions.headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: message.message,
        sent: message.sent
      })
    }

    dispatch(addInternalMessage(incidentId, agencyId, reportSchemaId, message))
    return fetch(`${Globals.apiEndpoint}/dashboard/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/internal_messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(ackPostInternalMessage(json))
        }
      })
      .catch(error => {
        dispatch(postInternalMessageFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}

export const updatePendingMessage = (message) => {
  return {
    type: UPDATE_PENDING_INTERNAL_MESSAGE,
    message
  }
}

export const clearPendingMessage = () => {
  return {
    type: CLEAR_PENDING_INTERNAL_MESSAGE
  }
}
