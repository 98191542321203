import {
  REQUEST_REPORT,
  RECEIVE_REPORT,
  REQUEST_REPORT_FAILURE,
} from "../actions/report";

const report = (
  state = {
    isFetching: false,
    isUpdating: false,
    newItem: {
      isNew: false,
      lastUpdated: null,
      type: "",
    },
    agencyId: null,
    items: [],
    languages: [],
    current_language: null,
    reports: [],
    totalPages: 0,
    en_count: 0,
    es_count: 0,
    count: 0,
    inc_types: [],
    timezones: [],
    lastUpdatedField: null,
    lastUpdatedResult: null,
    visibilityFilter: "SHOW_ALL",
  },
  action
) => {
  switch (action.type) {
    case REQUEST_REPORT:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_REPORT:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        languages: action.languages,
        current_language: action.current_language,
        reports: action.items,
        totalPages: action.totalPage,
        en_count: action.en_count,
        es_count: action.es_count,
        count: action.count,
        inc_types: action.inc_types,
        timezones: action.timezones,
        lastUpdated: action.receivedAt,
      };
    case REQUEST_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default report;
