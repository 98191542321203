import {
  REQUEST_REPORT_CSV,
  RECEIVE_REPORT_CSV,
  REQUEST_REPORT_FAILURE_CSV,
  CLEAR_IS_FETCHING,
} from "../actions/csvReports";

const reportCSV = (
  state = {
    isFetchingcsv: "",
    isUpdating: false,
    newItem: {
      isNew: false,
      lastUpdated: null,
      type: "",
    },
    agencyId: null,
    items: [],
    languages: [],
    current_language: null,
    reports: [],
    totalPages: null,
    lastUpdatedField: null,
    lastUpdatedResult: null,
    visibilityFilter: "SHOW_ALL",
  },
  action
) => {
  switch (action.type) {
    case REQUEST_REPORT_CSV:
      return {
        ...state,
        isFetchingcsv: "fetching",
      };
    case RECEIVE_REPORT_CSV:
      console.log("RECEIVE_REPORT_CSV", action);
      return {
        ...state,
        isFetchingcsv: "receive_data_success",
        items: action.items,
        languages: action.languages,
        current_language: action.current_language,
        reports: action.items,
        totalPages: action.totalPage,
        lastUpdated: action.receivedAt,
      };
    case REQUEST_REPORT_FAILURE_CSV:
      return {
        ...state,
        isFetchingcsv: "receive_data_failure",
        error: action.error,
      };
    case CLEAR_IS_FETCHING:
      return {
        ...state,
        isFetchingcsv: "",
      };
    default:
      return state;
  }
};

export default reportCSV;
