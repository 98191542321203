/* global localStorage */
import { EventEmitter } from 'events';
import { isTokenExpired } from './jwtHelper';
import Globals from './globals';
import auth0 from 'auth0-js';
import { logException } from './AppLogger';
import BSGStar from '../assets/images/BSGStar.png';

export default class AuthService extends EventEmitter {
  auth0 = new auth0.WebAuth({
    domain: Globals.auth0Domain,
    rememberLastLogin: false,
    clientID: Globals.auth0Client,
    redirectUri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }/callback`,
    responseType: 'token id_token',
    scope: 'openid profile'
  });

  constructor(clientId, domain) {
    super();

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      clientCallback: null
    };

    this.setProfile = this.setProfile.bind(this);
  }

  doAuthentication(authResult) {
    this.auth0.client.userInfo(authResult.accessToken, (error, profile) => {
      if (profile) {
        this.setProfile(profile);
      } else {
        console.log(error);
        logException(error, {
          message: 'Could not load profile for user',
          token: authResult.accessToken
        });
      }
    });
  }

  authorizationError(error) {
    this.lock.show({
      flashMessage: {
        type: 'error',
        text: error.error_description
      }
    });
  }

  setProfile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile));
    this.emit('profile_updated', profile);
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log(authResult);
        this.doAuthentication(authResult);
        this.setSession(authResult);
      } else if (err) {
        console.log(authResult);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  }

  login(cb) {
    this.auth0.authorize();
  }

  loggedIn() {
    const token = this.getIdToken();

    return !!token && !isTokenExpired(token);
  }

  getProfile() {
    const profile = localStorage.getItem('profile');
    return profile ? JSON.parse(localStorage.profile) : {};
  }

  setIdToken(idToken) {
    localStorage.setItem('id_token', idToken);
  }

  getIdToken() {
    return localStorage.getItem('id_token');
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('selected_agency');
    localStorage.removeItem('profile');
    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
      }/login`,
      clientID: Globals.auth0Client
    });
  }
}
