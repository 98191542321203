import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import './IncidentAction.css'

// Modal actions
import { showModal } from '../../actions/modal'
import { postIncidentState, updateIncidentStatus, deleteReport } from '../../actions/reportSummaries'
const IncidentAction = ({ incident, agency, reportSchemaId, languageCode, dispatch, profile }) => {
  let myAdmin = false
  if (profile.details.is_admin == true) {
      myAdmin = true
  }
  //console.log('This is myAdmin: ', myAdmin)
  let noDept = false
  if(undefined !== agency.details.assignees && agency.details.assignees.departments.length > 0){
      noDept = true
  } else {
      noDept = false
  }
  const reportSchema = agency.details.report_schemas
    ? agency.details.report_schemas.find(schema => schema._id === reportSchemaId)
    : []
  // Get report states for dashboard only
  let reportStates = []
  let reportStatuses = []
  let selectedStatus = {}
  let selectedState = {}
  if (undefined !== reportSchema) {
    reportStates = reportSchema.report_states
        ? reportSchema.report_states.filter(state => state.types.find(type => type === 'dashboard'))
        : []

    reportStatuses = reportSchema.report_statuses
    // Get selected state and status
    selectedState = incident.state && reportSchema.report_states
        ? reportSchema.report_states.find(state => state._id === incident.state)
        : {}
    selectedStatus = selectedState && reportStatuses
        ? reportStatuses.find(status => status._id === selectedState.report_status_id)
        : {}
  }

  const circle = selectedStatus
    ? <div className='status-dropdown'>
      <div className='status-circle' style={{background: selectedStatus.color}} />
      {selectedStatus.title ? selectedStatus.title[languageCode] : 'Closed'}
    </div>
    : <div className='status-dropdown'>Select</div>

  const selectChat = () => {
    const incidentChatProps = {
      incident,
      agencyId: agency.agencyId,
      reportSchemaId
    }

    dispatch(showModal('INCIDENT_CHAT', incidentChatProps))
  }

  const selectNotes = () => {
    const incidentNotesProps = {
      incident,
      agencyId: agency.agencyId,
      reportSchemaId
    }

    dispatch(showModal('INCIDENT_NOTES', incidentNotesProps))
  }

  const selectLog = () => {
    const incidentLogProps = {
      incident,
      agencyId: agency.agencyId,
      reportSchemaId
    }

    dispatch(showModal('INCIDENT_LOG', incidentLogProps))
  }

  const selectResource = () => {
    const incidentResourceProps = {
      incident,
      languageCode,
      agency: agency
    }

    dispatch(showModal('INCIDENT_RESOURCE', incidentResourceProps))
  }

  const checkDelete = () => {
    let r = window.confirm('Are you sure you want to delete this report?\nWarning: this cannot be undone.');
    if (r == true) {
      deleteIncident()
    } else {
    }
  }

  const deleteIncident = () => {
    dispatch(deleteReport(incident._id, incident.agency_id, incident.report_schema_id))
  }

  const selectState = (eventKey) => {
    dispatch(postIncidentState(incident._id, agency.agencyId, reportSchemaId, eventKey))

    const selectedState = reportStates.find(state => state._id === eventKey)
    if (selectedState) dispatch(updateIncidentStatus(incident._id, selectedState.report_status_id))
  }

  const setIncidentChatBackground = () => {
    const chatSummary = incident.chat_summary

    if (!chatSummary) return null

    if (chatSummary.status === 'open') {
      return chatSummary.user_last_received > chatSummary.dashboard_last_received
        ? 'user-recent'
        : 'dashboard-recent'
    } else if (chatSummary.status === 'closed') {
      return chatSummary.user_last_received || chatSummary.dashboard_last_received
        ? 'chat-closed'
        : null
    }

    return null
  }

  const setNoteButtonBackground = () => {
    const noteSummary = incident.note_summary

    if (!noteSummary) return null
    if (noteSummary.dashboard_last_received) return 'note-received'

    return null
  }

  const setLogButtonBackground = () => {
    const internalMessageSummary = incident.internal_message_summary

    if (!internalMessageSummary) return null
    if (internalMessageSummary.last_received) return 'log-received'

    return null
  }
  // var this_class = 'no-responder';
  // if (noDept){
  //   this_class = 'yes-responder';
  // }
  return (
    <div>
      <section className='status-container'>
        <div className='action-status'>
          <div>Status</div>
          <DropdownButton id='state-button' title={circle} onSelect={selectState}>
            {reportStates.filter(state => state._id !== incident.state).map(state => {
              const status = reportStatuses.find(status => status._id === state.report_status_id)

              return (
                <MenuItem eventKey={state._id} key={state._id}>{status.title[languageCode]}</MenuItem>
              )
            }
            )}
          </DropdownButton>
        </div>
      </section>
      <section className="action-container">
        <div className='action-chat'>
          <div>User<br />Chat</div>
          <Button bsSize='small' id='chat-button' className={setIncidentChatBackground()} onClick={selectChat}>
            <i id='chat-icon' className='material-icons'>chat</i>
          </Button>
        </div>
        <div className='action-note'>
          <div>User<br />Note</div>
          <Button bsSize='small' id='note-button' className={setNoteButtonBackground()} onClick={selectNotes}>
            <i id='note-icon' className='material-icons'>note_add</i>
          </Button>
        </div>
        <div className='action-log'>
          <div>Dispatch<br />Log</div>
          <Button bsSize='small' id='log-button' className={setLogButtonBackground()} onClick={selectLog}>
            <i id='log-icon' className='material-icons'>view_headline</i>
          </Button>
        </div>
        {noDept && <div className='action-resource'>
          <div>Unit<br />Resource</div>
          <Button bsSize='small' id='resource-button' onClick={selectResource}>
            <i id='resource-icon' className='material-icons'>check</i>
          </Button>
        </div>}
        { myAdmin === true &&
          <div className='action-log'>
            <div>Delete<br/>&nbsp;</div>
            <Button bsSize='small' className="del-action" id='delete-button' onClick={checkDelete}>
              <i id='delete-icon' className='material-icons'>delete</i>
            </Button>
          </div>
        }
      </section>
    </div>
  )
}

IncidentAction.propTypes = {
  incident: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
  reportSchemaId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  languageCode: PropTypes.string
}

export default connect()(IncidentAction)
