import React, { Component } from 'react'
import { Alert, Collapse } from 'react-bootstrap'
import './Notification.css'

class Notification extends Component {
  componentDidMount () {
    setTimeout(() => {
      this.props.onDismiss()
    }, 3000)
  }

  render () {
    const { onDismiss, text, type } = this.props

    return (
      <Collapse in={this.props.show} transitionAppear timeout={3000} unmountOnExit>
        <Alert id='update-notice' className='center-block' bsStyle={type} onDismiss={onDismiss}>
          <p>{text}</p>
        </Alert>
      </Collapse>
    )
  }
}

export default Notification
