/* globals fetch, localStorage */
import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

// Fetch
export const REQUEST_MESSAGES = 'REQUEST_MESSAGES'
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES'
export const REQUEST_MESSAGES_FAILURE = 'REQUEST_MESSAGES_FAILURE'
// Pending Messages
export const UPDATE_PENDING_MESSAGE = 'UPDATE_PENDING_MESSAGE'
export const CLEAR_PENDING_MESSAGE = 'CLEAR_PENDING_MESSAGE'
// Chat Messages
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE'
export const ACK_ADD_CHAT_MESSAGE = 'ACK_ADD_CHAT_MESSAGE'
export const ADD_CHAT_MESSAGE_FAILURE = 'ADD_CHAT_MESSAGE_FAILURE'
// Notes Messages
export const ADD_NOTE = 'ADD_NOTE'
export const ACK_ADD_NOTE = 'ACK_ADD_NOTE'
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE'
// Clear
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const requestMessages = (incidentId, agencyId, reportSchemaId) => {
  return {
    type: REQUEST_MESSAGES,
    incidentId,
    agencyId,
    reportSchemaId
  }
}

export const receiveMessages = (json) => {
  return {
    type: RECEIVE_MESSAGES,
    messages: json,
    receivedAt: Date.now()
  }
}

export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES
  }
}

export const requestMessagesFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_MESSAGES_FAILURE,
    error
  }
}

export const fetchMessages = (incidentId, agencyId, reportSchemaId) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)

    dispatch(requestMessages(incidentId, agencyId, reportSchemaId))

    return fetch(`${Globals.apiEndpoint}/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveMessages(json))
        }
      })
      .catch(error => {
        dispatch(requestMessagesFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}

export const addChatMessage = (incidentId, agencyId, reportSchemaId, message) => {
  return {
    type: ADD_CHAT_MESSAGE,
    incidentId,
    agencyId,
    reportSchemaId,
    message
  }
}

export const postChatMessageFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: ADD_CHAT_MESSAGE_FAILURE,
    error
  }
}

export const ackPostChatMessage = (json) => {
  return {
    type: ACK_ADD_CHAT_MESSAGE,
    result: json,
    receivedAt: Date.now()
  }
}

export const postChatMessage = (incidentId, agencyId, reportSchemaId, message) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const defaulRequestOptions = getRequestOptions(token)
    const requestOptions = {
      method: 'POST',
      headers: {
        ...defaulRequestOptions.headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: message.type,
        message: message.message,
        sent: message.sent,
        display_name: message.display_name,
        sender_id: message.sender_id
      })
    }

    dispatch(addChatMessage(incidentId, agencyId, reportSchemaId, message))
    return fetch(`${Globals.apiEndpoint}/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(ackPostChatMessage(json))
        }
      })
      .catch(error => {
        dispatch(postChatMessageFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}

export const addNote = (incidentId, agencyId, reportSchemaId, message) => {
  return {
    type: ADD_NOTE,
    incidentId,
    agencyId,
    reportSchemaId,
    message
  }
}

export const postNoteMessageFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: ADD_NOTE_FAILURE,
    error
  }
}

export const ackPostNoteMessage = (json) => {
  return {
    type: ACK_ADD_NOTE,
    result: json,
    receivedAt: Date.now()
  }
}

export const postNoteMessage = (incidentId, agencyId, reportSchemaId, message) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const defaulRequestOptions = getRequestOptions(token)
    const requestOptions = {
      method: 'POST',
      headers: {
        ...defaulRequestOptions.headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: message.type,
        message: message.message,
        sent: message.sent,
        display_name: message.display_name,
        sender_id: message.sender_id
      })
    }

    dispatch(addNote(incidentId, agencyId, reportSchemaId, message))
    return fetch(`${Globals.apiEndpoint}/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/messages`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(ackPostNoteMessage(json))
        }
      })
      .catch(error => {
        dispatch(postNoteMessageFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}

export const updatePendingMessage = (message) => {
  return {
    type: UPDATE_PENDING_MESSAGE,
    message
  }
}

export const clearPendingMessage = () => {
  return {
    type: CLEAR_PENDING_MESSAGE
  }
}
