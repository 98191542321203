import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import AuthService from '../../utils/AuthService'
import Globals from '../../utils/globals'
import { setProfile, authCalled } from '../../actions/profile'
import { clearError } from '../../actions/error'
import BSGBackground from '../../assets/images/BSGCONSOLE-WEB-bckgnd.png'
import './Login.css'

const auth = new AuthService(Globals.auth0Client, Globals.auth0Domain)

class Login extends React.Component {
  componentWillMount () {
    const { dispatch } = this.props

    if (!auth.loggedIn() && !this.props.authCalled) {
      auth.login(() => {
        dispatch(setProfile(auth.getProfile()))
        dispatch(clearError())
      })

      dispatch(authCalled())
    }
  }

  render () {
    const { from } = this.props.location.state || { from: { pathname: '/' } }

    const { redirectToReferrer } = this.props
    if (redirectToReferrer && auth.loggedIn()) {
      return (
        <Redirect to={from} />
      )
    }

    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    authCalled: state.profile.authCalled || false,
    redirectToReferrer: state.profile.redirectToReferrer || false
  }
}

export default connect(mapStateToProps)(Login)
