import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { hideModal } from '../../actions/modal'
import { postUnitRoleAndShiftIncidentAssignment } from '../../actions/reportSummaries'
import { Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import './SelectStartDateModal.css'

const SelectStartDateModal = ({
  agency,
  incident,
  unitId,
  shiftId,
  roleId,
  reportSchemaId,
  dispatch,
  visible,
  languageCode
}) => {
  const hide = () => dispatch(hideModal())

  const saveSelection = (startDate) => {
    let date = Date.parse(startDate)
    const offset = new Date().getTimezoneOffset() * 60000
    date = date - offset

    dispatch(hideModal())
    dispatch(postUnitRoleAndShiftIncidentAssignment(incident, agency._id, reportSchemaId, unitId, roleId, shiftId, date))
  }

  return (
    <Modal show={visible} onHide={hide} id='start-date-modal' dialogClassName='assignment-modal' backdrop>
      <Modal.Header closeButton closeLabel='Cancel'>
        <Modal.Title>Select Start Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          inline
          onChange={saveSelection} />
      </Modal.Body>
    </Modal>
  )
}

export default connect()(SelectStartDateModal)
