/* 2023 created for BSG by jy around 2016: 
** LOCATION:  src/views/Reports/Reports.js  verison 3.0.1
**            xt this was created in the orginal system in 2016
**            xt I want clean readable, maintainable code
**            xt please keep up with the version numbers so we can tell if prod up to date!
** PLEASE PUT ALL CHANGE HISTORY NOTES HERE AS WELL AS IN THE CODE LOCATION ITSELF!!!
** PLEASE USE USA TIME FORMAT  MM/DD/YYYY  OR YYYYMMDD
** 20230915: - GK made changes to keep the Reports Tab table, sticky so it stays on top when scrolling.
**             and to create scroll bars horiz, vert.
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, PageHeader, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "react-select/dist/react-select.css";

// Services
import LocalizationService from "../../utils/LocalizationService";

// Utilities
import { getAgencyStatusMap } from "../../utils/ObjectFilters";

// Actions
import { requestStubs, selectAgencyId } from "../../actions/agencyStubs";
import { fetchAgency } from "../../actions/agency";
// import {
//   // fetchReportSummaries,
//   // dismissNotification,
//   // clearNewItem,
//   // setReportVisibility
// } from '../../actions/reportSummaries'

import { fetchReport } from "../../actions/report";
import { fetchReportCSV, clearIsfetching } from "../../actions/csvReports";

// Components
import AgencyList from "../../components/Agencies/AgencyList";
import Reports from "../../components/Reports/Reports";
import ModalRoot from "../../components/Modal/ModalRoot";
// import Notification from '../Notification/Notification'
// import StateTabs from '../StateTabs/StateTabs'
import Radio from "react-bootstrap/lib/Radio";
// Selectors
import { getVisibleReportSummaries } from "../../selectors/reports";
import { languageLabel } from "../../utils/Languages";
import { ClipLoader } from "react-spinners";
class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: "",
      endDate: "",
      current_language: "en_US",
      checkboxvalue: [],
      checkboxStatusvalue: [],
      checkboxLanguagevalue: [],
      rowPerPage: 10,
      curPage: 1,
      totalPagesIN: 0,
      updatePageRow: 10,
      isFetching: false,
      csvReports: [],
      showLimitModal: false,
      timezone: "",
      /*New code added by GK 25-08-2023 to create scroll bars start*/
      width: window.innerWidth,
      height: window.innerHeight,
      /*New code added by GK 25-08-2023 end*/
    };
/*New code added by GK 09-15-2023 to update the windows dim start*/
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
/*New code added by GK 09-15-2023 end*/
    this.handleChange = this.handleChange.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.prepareFetch = this.prepareFetch.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleStatusCheckBox = this.handleStatusCheckBox.bind(this);
    this.handlePageRow = this.handlePageRow.bind(this);
    this.handlePageSize = this.handlePageSize.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleCsv = this.handleCsv.bind(this);
    this.handleclearAll = this.handleclearAll.bind(this);
    this.handleCheckBoxLanguage = this.handleCheckBoxLanguage.bind(this);
    this.handleExportModalClose = this.handleExportModalClose.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      agencyStubs,
      dispatch,
      match,
      reports,
      reportCSV,
      isFetchingcsv,
      // timezones,
    } = nextProps;

    // if (timezones) {
    //   this.setState({
    //     timezone: timezones[0],
    //   });
    // }

    if (reportCSV) {
      this.setState(
        {
          csvReports: reportCSV.items,
          isFetchingcsvv: isFetchingcsv,
        },
        () => this.downloadReport
      );
    }

    if (!agencyStubs.selectedAgencyId) {
      const agencyId = match.params.id || null;

      if (agencyId) {
        dispatch(selectAgencyId(agencyId));
      } else if (agencyStubs.items.length === 1) {
        dispatch(selectAgencyId(agencyStubs.items[0]._id));
      }
    }
    // console.log('props: ',this.props)

    // if (this.props.reports){
    //   console.log(this.props)
    //   this.startPoll(agencyStubs.selectedAgencyId)
    // }

    if (this.props.report.lastUpdated) {
      // console.log('REPORTComponent', 'this.props.reports', this.props.reports)
      // console.log('REPORTComponent', 'reports.reports',reports.reports)
      // console.log(this.props.reports === reports.reports)
      // console.log(reports.items)
      if (this.props.reports !== reports.reports) {
        // console.log('REPORTComponent', 'not equal', nextProps.report.isFetching)
        clearTimeout(this.timeout);
        this.setState({
          isFetching: nextProps.report.isFetching,
        });
        if (!nextProps.report.isFetching) {
          if (
            !this.state.current_language &&
            nextProps.report.current_language
          ) {
            this.setState({
              current_language: nextProps.report.current_language,
              totalPagesIN: nextProps.report.totalPages,
            });
          }
          // this.startPoll(agencyStubs.selectedAgencyId);
        }
      }
    } else {
      if (!this.props.report.isFetching) {
        this.prepareFetch(agencyStubs.selectedAgencyId);
      }
    }
  }

  componentWillMount() {
    const { agencyStubs, agency, dispatch } = this.props;

    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length === 0) {
      dispatch(requestStubs());
    } else if (agencyStubs.selectedAgencyId) {
      if (!agency.details._id)
        dispatch(fetchAgency(agencyStubs.selectedAgencyId));
      // this.prepareFetch(agencyStubs.selectedAgencyId)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentWillUpdate(nextProps, nextState) {
    const { agencyStubs, dispatch } = this.props;

    if (
      agencyStubs.selectedAgencyId !== nextProps.agencyStubs.selectedAgencyId
    ) {
      dispatch(fetchAgency(nextProps.agencyStubs.selectedAgencyId));
      this.prepareFetch(nextProps.agencyStubs.selectedAgencyId);
    }
  }

  componentDidMount() {
    this.prepareFetch();
    /*New code added by GK 09-15-2023 start*/
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 350,
    });
    window.addEventListener("resize", this.updateWindowDimensions);
    /*New code added by GK 09-15-2023 end*/
  }
  /*New code added by GK 09-15-2023 start*/
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 300,
    });
  }
  /*New code added by GK 09-15-2023 end*/
  /**
   * @author Sultan
   * 11-03-2023
   * on change of the timezone the value being passed.
   */

  handleTimezone(data) {
    this.setState(
      {
        timezone: data.value === "Select timezone" ? "" : data.value,
      },
      () => {
        console.log("timzone called");
      }
    );
  }
  // startPoll(agencyId) {
  //   const { agencyStubs } = this.props;
  //   if (!agencyId) {
  //     agencyId = agencyStubs.selectAgencyId;
  //   }
  //   this.timeout = setTimeout((filter) => {
  //     this.prepareFetch(agencyId);
  //   }, 10000);
  // }
  prepareFetch() {
    const { dispatch, agencyStubs } = this.props;
    let dates = moment(this.state.endDate, "MM/DD/YYYY hh:mm:ss A").format(
      "MM/DD/YYYY HH:mm:ss"
    );
    let formattedState = {
      start: this.state.startDate && moment(this.state.startDate).valueOf(),
      end:
        this.state.endDate &&
        moment(
          moment(dates)
            .add({
              hours: 23,
              minutes: 59,
              seconds: 59,
            })
            .format("MM/DD/YYYY HH:mm:ss")
        ).valueOf(),
      current_language: this.state.current_language,
      pageSize: this.state.rowPerPage,
      page: this.state.curPage,

      user_language: JSON.stringify(this.state.checkboxLanguagevalue),
      status_ids: JSON.stringify(this.state.checkboxStatusvalue),
      title: JSON.stringify(this.state.checkboxvalue),
    };
    const params = {};
    for (let queryParam of [
      "start",
      "end",
      "current_language",
      "pageSize",
      "page",
      "user_language",
      "status_ids",
      "title",
    ]) {
      if (formattedState[queryParam] !== undefined) {
        params[queryParam] = formattedState[queryParam];
      }
    }
    dispatch(fetchReport(agencyStubs.selectedAgencyId, params));
  }
  handleChange(e) {
    var tar = e.target.id;
    if (e.target.id == "startDate") {
      this.setState({ startDate: e.target.value });
    } else if (e.target.id == "endDate") {
      this.setState({ endDate: e.target.value });
    }
  }
  handleStart(e) {
    this.setState({
      startDate: e,
    });
  }
  handleEnd(e) {
    this.setState({
      endDate: e,
    });
  }
  handleLanguageChange = (current_language_option) => {
    const current_language = current_language_option.value;
    this.state.current_language = current_language;
    this.setState({ current_language });
    clearTimeout(this.timeout);
    this.prepareFetch();
    // this.startPoll();
  };
  /**
   * @author Sultan
   * 03-03-2023
   * Incident type checkbox filter handler.
   */

  handleCheckBox(e) {
    const { dispatch, agencyStubs } = this.props;

    const isChecked = e.target.checked;
    if (isChecked) {
      this.setState(
        {
          checkboxvalue: [...this.state.checkboxvalue, e.target.value],
        },
        () => this.prepareFetch()
      );
    } else {
      const index = this.state.checkboxvalue.indexOf(e.target.value);
      this.state.checkboxvalue.splice(index, 1);
      this.setState({ checkboxvalue: this.state.checkboxvalue }, () =>
        this.prepareFetch()
      );
    }
  }

  /**
   * @author Sultan
   * 03-03-2023
   * Export Modal Open Handler.
   */
  handleExportModalOpen(e) {
    this.setState({ isFetchingcsvv: "" });
  }
  /**
   * @author Sultan
   * 03-03-2023
   * Export Modal close after download.
   */
  handleExportModalClose(e) {
    const { dispatch } = this.props;
    this.setState({
      showLimitModal: false,
    });
    dispatch(clearIsfetching());
  }

  /**
   * @author Sultan
   * 03-03-2023
   * Status checkbox filter handler.
   */
  handleStatusCheckBox(e) {
    const isChecked = e.target.checked;
    if (isChecked) {
      this.setState(
        {
          checkboxStatusvalue: [
            ...this.state.checkboxStatusvalue,
            e.target.value,
          ],
        },
        () => this.prepareFetch()
      );
    } else {
      const index = this.state.checkboxStatusvalue.indexOf(e.target.value);
      this.state.checkboxStatusvalue.splice(index, 1);
      this.setState(
        { checkboxStatusvalue: this.state.checkboxStatusvalue },
        () => this.prepareFetch()
      );
    }
  }
  /**
   * @author Sultan
   * 03-03-2023
   * User language checkbox filter handler.
   */
  handleCheckBoxLanguage(e) {
    const isChecked = e.target.checked;
    if (isChecked) {
      this.setState(
        {
          checkboxLanguagevalue: [
            ...this.state.checkboxLanguagevalue,
            e.target.value,
          ],
        },
        () => this.prepareFetch()
      );
    } else {
      const index = this.state.checkboxLanguagevalue.indexOf(e.target.value);
      this.state.checkboxLanguagevalue.splice(index, 1);
      this.setState(
        {
          checkboxLanguagevalue: this.state.checkboxLanguagevalue,
        },
        () => this.prepareFetch()
      );
    }
    // this.prepareFetch();
  }
  /**
   * @author Sultan
   * 03-03-2023
   * Update page row size function.
   */
  handlePageRow(e) {
    this.setState(
      {
        rowPerPage: e,
        updatePageRow: e,
      },
      () => {
        this.prepareFetch();
      }
    );
  }
  /**
   * @author Sultan
   * 03-03-2023
   * Update page size function.
   */
  handlePageSize(e) {
    if (e.target.value) {
      this.setState(
        {
          curPage: e.target.value,
        },
        () => {
          this.prepareFetch();
        }
      );
    }
  }

  /**
   * @author Sultan
   * 03-03-2023
   * Pagination next handler.
   */
  handleNext(e) {
    const { totalPages } = this.props;
    if (this.state.curPage !== totalPages && !this.state.isFetching) {
      this.setState(
        {
          curPage: this.state.curPage + 1,
        },
        () => {
          this.prepareFetch();
        }
      );
    }
  }

  /**
   * @author Sultan
   * 03-03-2023
   * Pagination previous handler.
   */

  handlePrev() {
    if (this.state.curPage > 1 && !this.state.isFetching) {
      this.setState(
        {
          curPage: this.state.curPage - 1,
        },
        () => {
          this.prepareFetch();
        }
      );
    }
  }

  /**
   * @author Sultan
   * 03-03-2023
   * fetch export all data function.
   * below is the limit declared of how much data a user can export at a time.
   * Now Export limit as 500.
   */

  handleCsv() {
    const { dispatch, agencyStubs, count } = this.props;
    if (count <= 500) {
      let dates = moment(this.state.endDate, "MM/DD/YYYY hh:mm:ss A").format(
        "MM/DD/YYYY HH:mm:ss"
      );
      let formattedState = {
        start: this.state.startDate && moment(this.state.startDate).valueOf(),
        end:
          this.state.endDate &&
          moment(
            moment(dates)
              .add({
                hours: 23,
                minutes: 59,
                seconds: 59,
              })
              .format("MM/DD/YYYY HH:mm:ss")
          ).valueOf(),
        current_language: this.state.current_language,
        user_language: JSON.stringify(this.state.checkboxLanguagevalue),
        status_ids: JSON.stringify(this.state.checkboxStatusvalue),
        title: JSON.stringify(this.state.checkboxvalue),
      };
      const params = {};
      for (let queryParam of [
        "start",
        "end",
        "current_language",
        "user_language",
        "status_ids",
        "title",
      ]) {
        if (formattedState[queryParam] !== undefined) {
          params[queryParam] = formattedState[queryParam];
        }
      }
      /**
       * @author Sultan
       * 03-03-2023
       * create new file fetchReportCSV.
       */
      dispatch(fetchReportCSV(agencyStubs.selectedAgencyId, params));
    } else {
      this.setState({
        showLimitModal: true,
      });
    }
  }

  /**
   * @author Sultan
   * 03-03-2023
   * clear all filter function
   */

  handleclearAll() {
    if (
      this.state.checkboxvalue.length > 0 ||
      this.state.checkboxStatusvalue.length > 0 ||
      this.state.checkboxLanguagevalue.length > 0 ||
      this.state.startDate ||
      this.state.endDate
    ) {
      this.setState(
        {
          checkboxvalue: [],
          checkboxStatusvalue: [],
          checkboxLanguagevalue: [],
          startDate: "",
          endDate: "",
        },
        () => this.prepareFetch()
      );
    }
  }

  // <input type="text" name="start_date" id="startDate" placeholder="Start Date" onChange={this.handleChange}/>
  // <input type="text" name="start_date" id="endDate" placeholder="End Date" onChange={this.handleChange} />
  render() {
    const {
      agencyStubs,
      agency,
      // reportSummaries,
      reports,
      totalPages,
      error,
      languageCode,
      profile,
      current_language,
      languages,
      reportCSV,
      isFetchingcsv,
      isFetching,
      en_count,
      es_count,
      count,
      inc_types,
      timezones,
      /*New code added by GK 09-15-2023 to create scroll bars on report table start*/
      height,
      width,
      /*New code added by GK 09-15-2023 end*/
    } = this.props;
    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length > 0) {
      return (
        <AgencyList
          pathRef="/dashboard"
          agencies={agencyStubs.items}
          languageCode={languageCode}
        />
      );
    }
    return (
      <div className="report-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "10%" }}>
            <div style={{ width: "200px", paddingLeft: "1rem" }}>
              Language:
              <Select
                name="form-field-name"
                clearable={false}
                value={current_language}
                onChange={this.handleLanguageChange}
                options={["en_US", "es_US"].map((language) => ({
                  value: language,
                  label: languageLabel(language),
                }))}
                // options={languages.map((language) => ({
                //   value: language,
                //   label: languageLabel(language),
                // }))}
              />
            </div>
          </div>
          <div style={{ width: "90%" }}>
            <PageHeader>
              {agency.details.name ? agency.details.name[languageCode] : null}
            </PageHeader>
          </div>
        </div>
        {/* <Grid>
          <Row> */}
        {/* <div className="upper-container">
              <div className="btn-output">
                <Button
                  bsStyle="primary"
                  className="filter-btn"
                  onClick={this.prepareFetch}
                >
                  Submit
                </Button>
              </div>
              <div className="datepicker-output">
                <DatePicker
                  id="startDate"
                  selected={this.state.startDate}
                  onChange={this.handleStart}
                  placeholderText="Start Date"
                  className="start-datepicker"
                />
                <DatePicker
                  id="endDate"
                  selected={this.state.endDate}
                  onChange={this.handleEnd}
                  placeholderText="End Date"
                  className="end-datepicker"
                />
              </div>
              <div style={{ width: "200px", paddingLeft: "1rem" }}>
                Language:
                <Select
                  name="form-field-name"
                  clearable={false}
                  value={current_language}
                  onChange={this.handleLanguageChange}
                  options={languages.map((language) => ({
                    value: language,
                    label: languageLabel(language),
                  }))}
                />
              </div>
            </div> */}
        {/* </Row>
        </Grid> */}

        <div className="table-container">
          <Reports
            dispatch={this.props.dispatch}
            reports={reports}
            reportCSV={reportCSV.items}
            agency={agency}
            languageCode={languageCode}
            profile={profile}
            checkboxvalue={this.state.checkboxvalue}
            checkboxStatusvalue={this.state.checkboxStatusvalue}
            checkboxLanguagevalue={this.state.checkboxLanguagevalue}
            handleCheckBox={this.handleCheckBox}
            handleStatusCheckBox={this.handleStatusCheckBox}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            handleStart={this.handleStart}
            handleEnd={this.handleEnd}
            prepareFetch={this.prepareFetch}
            page={this.state.curPage}
            handlePageRow={this.handlePageRow}
            totalPage={totalPages}
            updatePageRow={this.state.updatePageRow}
            handlePageSize={this.handlePageSize}
            handleNext={this.handleNext}
            handlePrev={this.handlePrev}
            handleCsv={this.handleCsv}
            handleclearAll={this.handleclearAll}
            handleCheckBoxLanguage={this.handleCheckBoxLanguage}
            isFetchingcsv={isFetchingcsv}
            isFetching={isFetching}
            en_count={en_count}
            es_count={es_count}
            count={count}
            inc_types={inc_types}
            timezones={timezones}
            timezone={this.state.timezone}
            handleExportModalClose={this.handleExportModalClose}
            showLimitModal={this.state.showLimitModal}
            handleTimezone={this.handleTimezone}
            /*New code added by GK 09-15-2023 to create scrollbars on table start*/
            height={this.state.height}
            width={this.state.width}
            /*New code added by GK 09-15-2023 end*/
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencyStubs: state.agencyStubs || [],
    agency: state.agency || {},
    report: state.report || {},
    isFetching: state.report.isFetching || false,
    reportCSV: state.reportCSV || {},
    isFetchingcsv: state.reportCSV.isFetchingcsv || "",
    reports: state.report.reports || [],
    languages: state.report.languages || [],
    current_language: state.report.current_language || "en_US",
    totalPages: state.report.totalPages || null,
    en_count: state.report.en_count || 0,
    es_count: state.report.es_count || 0,
    count: state.report.count || 0,
    inc_types: state.report.inc_types || [],
    timezones: state.report.timezones || [],
    error: state.error || null,
    languageCode: LocalizationService.getCurrentLanguage(),
    profile: state.profile || {},
  };
};

export default connect(mapStateToProps)(Report);
