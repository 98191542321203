/* globals fetch, localStorage */
import Globals from "../utils/globals";
import { logException } from "../utils/AppLogger";
import { getToken, getRequestOptions } from "../utils/ApiHelper";
import { setError } from "./error";

export const REQUEST_REPORT = "REQUEST_REPORT";
export const RECEIVE_REPORT = "RECEIVE_REPORT";
export const REQUEST_REPORT_FAILURE = "REQUEST_REPORT_FAILURE";
// export const SET_REPORT_VISIBILITY = 'SET_REPORT_VISIBILITY'

export const requestReport = (id) => {
  return {
    type: REQUEST_REPORT,
    agencyId: id,
  };
};

export const receiveReport = (json) => {
  return {
    type: RECEIVE_REPORT,
    items: json.items,
    totalPage: json.totalPages,
    en_count: json.en_count,
    es_count: json.es_count,
    count: json.count,
    inc_types: json.inc_types,
    timezones: json.timezones,
    languages: json.languages,
    current_language: json.current_language,
    receivedAt: Date.now(),
  };
};

export const fetchReportFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: REQUEST_REPORT_FAILURE,
    error,
  };
};

// export const setReportVisibility = (visibility) => {
//   return {
//     type: SET_REPORT_VISIBILITY,
//     visibility
//   }
// }

export const fetchReport = (id, params = {}) => {
  console.log("params", params);
  return (dispatch) => {
    const token = getToken();
    let res;
    let fetchUrl;
    // let params
    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    fetchUrl = `${Globals.apiEndpoint}/dashboard/agencies/${id}/reporting`;
    if (Object.keys(params).length) {
      fetchUrl +=
        "?" +
        Object.keys(params)
          .map((queryParam) => `${queryParam}=${params[queryParam]}`)
          .join("&");
    }

    const requestOptions = getRequestOptions(token);

    dispatch(requestReport(id));
    return fetch(fetchUrl, requestOptions)
      .then((response) => {
        res = response;
        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          // console.log('json: ',json)
          dispatch(receiveReport(json));
        }
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};
