import React from 'react'
import { connect } from 'react-redux'
import SelectDepartmentModal from './SelectDepartmentModal'
import SelectUnitModal from './SelectUnitModal'
import SelectRoleModal from './SelectRoleModal'
import SelectShiftModal from './SelectShiftModal'
import SelectStartDateModal from './SelectStartDateModal'
import IncidentChatModal from './IncidentChatModal'
import FullscreenIncidentChatModal from './FullscreenIncidentChatModal'
import IncidentNotesModal from './IncidentNotesModal'
import IncidentImagesModal from './IncidentImagesModal'
import IncidentMapModal from './IncidentMapModal'
import IncidentLogModal from './IncidentLogModal'
import IncidentResourceModal from './IncidentResourceModal'
import './Modal.css'

const MODAL_COMPONENTS = {
  'SELECT_DEPARTMENT': SelectDepartmentModal,
  'SELECT_UNIT': SelectUnitModal,
  'SELECT_ROLE': SelectRoleModal,
  'SELECT_SHIFT': SelectShiftModal,
  'SELECT_START_DATE': SelectStartDateModal,
  'INCIDENT_CHAT': IncidentChatModal,
  'FULL_INCIDENT_CHAT': FullscreenIncidentChatModal,
  'INCIDENT_NOTES': IncidentNotesModal,
  'INCIDENT_IMAGES': IncidentImagesModal,
  'INCIDENT_MAP': IncidentMapModal,
  'INCIDENT_LOG': IncidentLogModal,
  'INCIDENT_RESOURCE': IncidentResourceModal
}

const ModalRoot = ({ modalType, modalProps, visible }) => {
  if (!modalType) {
    return <span />
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} visible={visible} />
}

export default connect(state => state.modal)(ModalRoot)
