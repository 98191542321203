import { createSelector } from "reselect";
import LocalizationService from "../utils/LocalizationService";

const languageCode = LocalizationService.getCurrentLanguage();
const getVisibilityFilter = (state) => state.reportSummaries.visibilityFilter;
const getReportSummaries = (state) => state.reportSummaries;
const getAgency = (state) => state.agency.details;
const getReportSchema = (agency, summary) =>
  agency.report_schemas.find(
    (schema) => schema._id === summary.report_schema_id
  );
const getAgencyStatusMap = (agency, name) =>
  agency.display_pages //xt WHAT is this doing?
    ? agency.display_pages.display_statuses.find(
        (status) => status.title[languageCode] === name
      )
    : [];

// xt this must only filter what was already fetched.. need to see where it was originally fetched..
const filterReportsByStatus = (reportSummaries, agency, filter) => {
  const statusName = filter.substring(filter.indexOf("_") + 1);
  //console.log("at filterReportByStatus the statusName with a - in it is ", statusName)
  console.log("at filterReportByStatus the filter is: ", filter);
  console.log(
    "this is in selectors/reportSummaries, this is agency BEFORE it is sent to getAgencyStatusMap :",
    agency
  );
  const statusMap = getAgencyStatusMap(agency, statusName);
  console.log(
    "at filterReportsByStatus after getAgencyStatusMap, agency should be agency.display_pages is: ",
    agency
  );
  console.log("at filterReportsByStatus, the statusMap is: ", statusMap);

  return reportSummaries.items.filter((summary) => {
    let statusId = summary.status_id;

    if (!statusId) {
      // Get the status for the current state
      const schema = getReportSchema(agency, summary);
      console.log("XT in filterReportByStatus: ", schema);
      const state = schema.report_states.find(
        (state) => state._id === summary.state
      );
      console.log("XT this is state: ", state);
      if (state !== undefined) {
        statusId = state.report_status_id;
      }
    }
    // console.log("this is statusMap:", statusMap)
    return statusMap && statusMap.report_statuses
      ? statusMap.report_statuses.indexOf(statusId) > -1
      : false;
  });
};

export const getVisibleReportSummaries = createSelector(
  [getVisibilityFilter, getReportSummaries, getAgency],
  (visibilityFilter, reportSummaries, agency) => {
    console.log(
      "in getVisibleReportSummaries, params are visib filer and agency",
      visibilityFilter,
      agency
    );
    switch (visibilityFilter) {
      case "SHOW_CHATTING":
        // case 'SHOW_ALL':
        return reportSummaries;
      default:
        return {
          ...reportSummaries,
          items: filterReportsByStatus(
            reportSummaries,
            agency,
            visibilityFilter
          ),
        };
    }
  }
);
