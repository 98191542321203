import {
  REQUEST_MESSAGES,
  RECEIVE_MESSAGES,
  REQUEST_MESSAGES_FAILURE,
  ADD_CHAT_MESSAGE,
  ACK_ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_FAILURE,
  UPDATE_PENDING_MESSAGE,
  CLEAR_PENDING_MESSAGE,
  ADD_NOTE,
  ACK_ADD_NOTE,
  ADD_NOTE_FAILURE,
  CLEAR_MESSAGES
} from '../actions/messages'

const getMessages = (msgs, msg) => {
  return [
    ...msgs,
    msg
  ]
}

const messages = (state = {
  isFetching: false,
  isUpdating: false,
  incidentId: null,
  agencyId: null,
  reportSchemaId: null,
  messages: []
}, action) => {
  switch (action.type) {
    case REQUEST_MESSAGES:
      return {
        ...state,
        isFetching: true,
        incidentId: action.incidentId,
        agencyId: action.agencyId,
        reportSchemaId: action.reportSchemaId
      }
    case RECEIVE_MESSAGES:
      return {
        ...state,
        isFetching: false,
        messages: action.messages,
        lastUpdated: action.receivedAt
      }
    case CLEAR_MESSAGES:
      return {
        isFetching: false,
        isUpdating: false,
        incidentId: null,
        agencyId: null,
        reportSchemaId: null,
        messages: []
      }
    case REQUEST_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case UPDATE_PENDING_MESSAGE: {
      return {
        ...state,
        isUpdating: false,
        pendingMessage: action.message
      }
    }
    case CLEAR_PENDING_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        pendingMessage: null
      }
    case ACK_ADD_CHAT_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        result: action.result
      }
    case ADD_CHAT_MESSAGE:
      return {
        ...state,
        isUpdating: true,
        messages: getMessages(state.messages, action.message)
      }
    case ADD_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error
      }
    case ACK_ADD_NOTE:
      return {
        ...state,
        isUpdating: false,
        result: action.result
      }
    case ADD_NOTE:
      return {
        ...state,
        isUpdating: true,
        messages: getMessages(state.messages, action.message)
      }
    case ADD_NOTE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error
      }
    default:
      return state
  }
}

export default messages
