import {
  REQUEST_AGENCY,
  RECEIVE_AGENCY,
  REQUEST_AGENCY_FAILURE
} from '../actions/agency'

const agency = (state = {
  isFetching: false,
  agencyId: null,
  details: {}
}, action) => {
  switch (action.type) {
    case REQUEST_AGENCY:
      return Object.assign({}, state, {
        isFetching: true,
        agencyId: action.agencyId
      })
    case RECEIVE_AGENCY:
      return Object.assign({}, state, {
        isFetching: false,
        details: action.details,
        lastUpdated: action.receivedAt
      })
    case REQUEST_AGENCY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    default:
      return state
  }
}

export default agency
