import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Glyphicon, FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import _ from 'lodash'
import './IncidentResponder.css'

// Actions
import { showModal } from '../../actions/modal'
import { putIncidentTag, deleteIncidentAssignment } from '../../actions/reportSummaries'

class IncidentResponder extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tag: ''
    }

    this.handleTagChange = this.handleTagChange.bind(this)
    this.updateTag = this.updateTag.bind(this)
    this.getDepartment = this.getDepartment.bind(this)
    this.selectDepartment = this.selectDepartment.bind(this)
    this.selectUnit = this.selectUnit.bind(this)
    this.getResponderState = this.getResponderState.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const { tag } = nextProps.incident

    if (tag !== this.state.tag) this.setState({ tag: tag || '' })
  }

  getDepartment (assignment) {
    const { agency } = this.props

    if (assignment.department_id) {
      if (agency.assignees) {
        var department = _.find(agency.assignees.departments, (dept) => {
          return dept._id === assignment.department_id
        })

        return department
      }
    }

    return null
  }

  getRoleUnitAndShift (assignment, timelessStart) {
    const { agency, languageCode } = this.props
    let roleUnitAndShift = ''

    if (assignment.role_id && agency.assignees) {
      var role = _.find(agency.assignees.roles, (role) => {
        return role._id === assignment.role_id
      })

      roleUnitAndShift = role ? `${role.abbreviation[languageCode]} : ` : null
    }

    if (assignment.unit_id && agency.assignees) {
      var unit = _.find(agency.assignees.units, (unit) => {
        return unit._id === assignment.unit_id
      })

      roleUnitAndShift = unit ? `${roleUnitAndShift}${unit.name[languageCode]}` : roleUnitAndShift
    }

    if (assignment.shift_id && agency.assignees) {
      var shift = _.find(agency.assignees.shifts, (shift) => {
        return shift._id === assignment.shift_id
      })

      roleUnitAndShift = shift ? `${roleUnitAndShift} ${shift.abbreviation[languageCode]}` : roleUnitAndShift
    }

    if (timelessStart) {
      return `${roleUnitAndShift} ${moment(timelessStart).utc().format('L')}`
    }

    return roleUnitAndShift || 'Assign'
  }

  selectDepartment () {
    const { incident, agency, dispatch, languageCode } = this.props
    const departmentModalProps = {
      agency,
      incident,
      reportSchemaId: incident.report_schema_id,
      assignedDepartment: incident.department_id,
      departments: agency.assignees.departments,
      languageCode
    }

    dispatch(showModal('SELECT_DEPARTMENT', departmentModalProps))
  }

  selectUnit () {
    const { incident, agency, dispatch, languageCode } = this.props
    const unitModalProps = {
      agency,
      incident,
      assignedUnit: incident.unit_id,
      units: agency.assignees.units,
      languageCode
    }

    dispatch(showModal('SELECT_UNIT', unitModalProps))
  }

  handleTagChange (event) {
    this.setState({ tag: event.target.value })
  }

  updateTag (event) {
    const { incident, dispatch } = this.props
    const tag = event.target.value
    event.preventDefault()

    if (incident.tag !== tag && tag) {
      dispatch(putIncidentTag(incident._id, incident.agency_id, incident.report_schema_id, tag))
    }
  }

  deleteIncidentAssignment (assignmentId) {
    const { incident, dispatch } = this.props

    dispatch(deleteIncidentAssignment(incident._id, incident.agency_id, incident.report_schema_id, assignmentId))
  }

  getResponderState (index = 0) {
    const { incident, agency, languageCode } = this.props
    const lastAssignments = index !== 0 ? incident.assignments[index - 1] : incident.assignments[index]
    const firstUnit = index === 1 && !lastAssignments.assignee.unit_id

    if ((index === 0 || firstUnit) && agency.report_schemas) {
      const schema = agency.report_schemas.find(schema => schema._id === incident.report_schema_id)

      if (schema) {
        const stateId = incident.responder_report_state_id ||
          schema.default_responder_state_id ||
          schema.default_state_id

        const state = schema.report_states.find(state => state._id === stateId)

        if (state) {
          return <div
            className='state-circle'
            style={{background: state.color}}
            title={state.title ? state.title[languageCode] : ''} />
        }
      }
    }

    return null
  }

  render () {
    const { incident, languageCode } = this.props
    const assignments = incident.assignments ? incident.assignments : []
    const rootAssignment = assignments[0] ? assignments[0].assignee : []
    const department = this.getDepartment(rootAssignment)

    return (
      <section>
        <div className='responder-dept'>
          <div>Dept</div>
          <Button id='dept-button' tabIndex='0' bsSize='small' onClick={this.selectDepartment}
            style={department
              ? { backgroundColor: department.color, color: 'white' }
              : null}
            className={rootAssignment.length > 0
              ? assignments[0].assignee.department_id ? null : 'required'
              : 'required'}>
            {department
              ? department.abbreviation[languageCode]
              : 'Assign'}
          </Button>
          <div className='unit-title'>Unit</div>
          {assignments.map((assignment, index) => {
            if (!assignment.assignee.unit_id && assignment.assignee.department_id && assignments.length === 1) {
              return (
                <span key={index}>
                  <Button key={index} id='unit-button' tabIndex='-1' bsSize='small' onClick={this.selectUnit} className='required'>
                    Assign
                  </Button>
                  {this.getResponderState(index)}
                </span>
              )
            } else if (!assignment.assignee.unit_id) {
              return null
            }

            return (<span key={index}>
              <Button tabIndex='-1' id='unit-button' bsSize='small' onClick={this.selectUnit}
                disabled={!!assignment.assignee.unit_id}
                className={assignment.assignee.unit_id
                  ? null
                  : assignment.assignee.department_id ? 'required' : null}
              >{this.getRoleUnitAndShift(assignment.assignee, assignment.timeless_start)}</Button>
              {
                assignment.assignee.unit_id &&
                <Button bsSize='xsmall' bsStyle='danger' className="del-unit" id='delete-button' onClick={this.deleteIncidentAssignment.bind(this, assignment._id)}>
                  <Glyphicon glyph='remove' />
                </Button>
              }
              {this.getResponderState(index)}
            </span>)
          })}
          {assignments.length > 0 && assignments[assignments.length - 1].assignee.unit_id &&
            <span>
              <Button id='unit-button' tabIndex='-1' bsSize='small' onClick={this.selectUnit}
                className={assignments[assignments.length - 1].assignee.unit_id ? 'required' : null}
              >Assign</Button>
            </span>
          }
          {assignments.length === 0 &&
            <span>
              <Button id='unit-button' tabIndex='-1' bsSize='small' disabled onClick={this.selectUnit}>Assign</Button>
              {this.getResponderState()}
            </span>
          }
        </div>
        <div className='responder-tag'>
        <div>Incident/Tag#</div>
          <FormGroup className='tag-form' onSubmit={this.updateTag}>
            <InputGroup id='incident-tag-form'>
              <FormControl
                id='incident-tag'
                type='number'
                className='required text-small'
                placeholder='Enter #'
                value={this.state.tag}
                onChange={this.handleTagChange}
                onBlur={this.updateTag}
                tabIndex='0'
              />
            </InputGroup>
          </FormGroup>
        </div>
      </section>      
    )
  }
}

IncidentResponder.propTypes = {
  incident: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired
}

export default connect()(IncidentResponder)
