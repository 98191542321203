/* globals window */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { Modal } from 'react-bootstrap'
import Map from '../../components/Map/Map'
import './IncidentMapModal.css'

class IncidentMapModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    }

    this.hide = this.hide.bind(this)
  }

  hide () {
    const { dispatch } = this.props

    dispatch(hideModal())
  }

  render () {
    const {
      incident,
      visible
    } = this.props

    const coordinatePoint = {
      coordinates: {
        lat: incident.coordinate.latitude,
        lng: incident.coordinate.longitude
      },
      title: incident.description,
      received: incident.received
    }

    return (
      <Modal show={visible} onHide={this.hide} id='map-modal' bsSize='large' backdrop>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='material-icons'>map</i>
            <span className='image-text'>Map - {incident.display_title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='map-modal-body'>
          <Map coords={[coordinatePoint]} />
        </Modal.Body>
      </Modal>
    )
  }
}

export default connect()(IncidentMapModal)
