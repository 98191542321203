import React from 'react'
import { connect } from 'react-redux'
import { hideModal, showModal, updateModalProps } from '../../actions/modal'
import { Modal, ListGroup, ListGroupItem, FormGroup, FormControl, InputGroup, Glyphicon } from 'react-bootstrap'

const SelectShiftModal = ({
  agency,
  incident,
  unitId,
  reportSchemaId,
  shifts,
  dispatch,
  visible,
  languageCode
}) => {
  const shiftsMaster = agency.assignees.shifts
  const hide = () => dispatch(hideModal())
  const saveSelection = (assignmentId) => {
    dispatch(hideModal())

    if (agency.assignees.roles) {
      const roleModalProps = {
        agency,
        incident,
        unitId,
        shiftId: assignmentId,
        reportSchemaId: incident.report_schema_id,
        assignedRole: incident.role_id,
        roles: agency.assignees.roles,
        languageCode
      }

      dispatch(showModal('SELECT_ROLE', roleModalProps))
    } else {
      const startDateModalProps = {
        agency,
        incident,
        unitId,
        shiftId: assignmentId,
        reportSchemaId: incident.report_schema_id,
        assignedRole: incident.role_id,
        roleId: null,
        languageCode
      }

      dispatch(showModal('SELECT_START_DATE', startDateModalProps))
    }
  }

  const filterList = (event) => {
    var updatedList = shiftsMaster
    updatedList = updatedList.filter((item) => {
      return item.name[languageCode].toLowerCase().search(
        event.target.value.toLowerCase()) !== -1
    })

    dispatch(updateModalProps('shifts', updatedList))
  }

  return (
    <Modal show={visible} onHide={hide} id='shift-modal' dialogClassName='assignment-modal' backdrop>
      <Modal.Header closeButton closeLabel='Cancel'>
        <Modal.Title>Select Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <InputGroup id='shift-search-input'>
            <InputGroup.Addon><Glyphicon glyph='search' /></InputGroup.Addon>
            <FormControl id='shift-search' type='text' placeholder='Search' onChange={filterList} />
          </InputGroup>
        </FormGroup>
        <ListGroup className='text-medium'>
          {shifts.map(shift =>
            <ListGroupItem key={shift._id} onClick={() => saveSelection(shift._id)}>
              <div className='shift-name'>{shift.name[languageCode]}</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default connect()(SelectShiftModal)
