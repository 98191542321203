/* 2023 created for BSG by jy around 2016: 
** LOCATION:  src/components/Reports/Reports.js  version: 3.2.1
**            xt this was created in the orginal system in 2016
**            xt I want clean readable, maintainable code
** PLEASE PUT ALL CHANGE HISTORY NOTES HERE AS WELL AS IN THE CODE LOCATION ITSELF!!!
** PLEASE USE USA TIME FORMAT  MM/DD/YYYY  OR YYYYMMDD
** 20230729- xt added this file Report.js from Gauttem who edited Sultan code to correct default tz and message times section
 **            xt I edit/fix the column width of the Incident # from 90 to 150 and change header from Inc# to Incident # so the 
 **             full number fits across the column and not wrap.
** 20230801: - xt added back the change to the column width size and label of the incident #
** 20230915: - GK made changes to keep the Reports Tab table, sticky so it stays on top when scrolling.
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Row, Col, Button, CloseButton } from "react-bootstrap";
import { FormattedDate, FormattedTime, IntlProvider } from "react-intl";
import Dropdown from "react-bootstrap/lib/Dropdown";
import DropdownToggle from "react-bootstrap/lib/DropdownToggle";
import DropdownMenu from "react-bootstrap/lib/DropdownMenu";
import Select from "react-select";
// xt please leave this space. it reminds me that I want 'moment' taken out at some point
import moment from "moment";
// xt leave space
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-table/react-table.css";
import "./NewReports.css";
import moments from "moment-timezone";
import { CSVLink } from "react-csv";
import { languageLabel } from "../../utils/Languages";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const Reports = ({
  dispatch,
  reports,
  agency,
  languageCode,
  profile,
  checkboxvalue,
  handleCheckBox,
  handleStatusCheckBox,
  startDate,
  endDate,
  handleStart,
  handleEnd,
  prepareFetch,
  page,
  totalPage,
  updatePageRow,
  handlePageRow,
  handlePageSize,
  handleNext,
  handlePrev,
  isFetching,
  handleCsv,
  reportCSV,
  handleclearAll,
  checkboxStatusvalue,
  handleCheckBoxLanguage,
  checkboxLanguagevalue,
  isFetchingcsv,
  handleExportModalClose,
  en_count,
  es_count,
  count,
  showLimitModal,
  inc_types,
  timezones,
  timezone,
  handleTimezone,
  /*New code added by GK 09-15-2023 start - will be updated by updateWindowDimensions in state*/
  height,
  /*New code added by GK 09-15-2023 end*/
}) => {
  var data = [];
  var headers = [];
  let csvData = reportCSV;

  // Regular expression pattern to match epoc dates in 10 to 12 digit format
  const dateRegex = /\b\d{10,13}\b/g;
  // try to make a regex to match the unix epoc time string.?
  const stringRegex = /\d{13}/g; // xt i am trying to see if we can match the 13 digit string of epoch

  /**
   * @author Sultan
   * 03-03-2023
   * get csv data and date formated.
   */
  const csvdata = csvData.map((i) => {
    return {
      ...i,
      ...{
        start: timezone
          ? moments(timezoneformatDate(i.start))
              .tz(timezone)
              .format("MM/DD/YY HH:mm")
          : epocToUTCDefault(i.start),
      },
      ...{
        received: timezone
          ? moments(timezoneformatDate(i.received))
              .tz(timezone)
              .format("MM/DD/YY HH:mm")
          : epocToUTCDefault(i.received),
      },
      ...{
        occurred: timezone
          ? moments(timezoneformatDate(i.occurred))
              .tz(timezone)
              .format("MM/DD/YY HH:mm")
          : epocToUTCDefault(i.occurred),
      },
      ...{
        sent: timezone
          ? moments(timezoneformatDate(i.sent))
              .tz(timezone)
              .format("MM/DD/YY HH:mm")
          : epocToUTCDefault(i.sent),
      },
      ...{
        messages: timezone
          ? messagesToTimezone(i.messages, timezone)
          : messagesToTimezone(i.messages, "default"),
      },
      ...{
        internal: timezone
          ? messagesToTimezone(i.internal, timezone)
          : messagesToTimezone(i.internal, "default"),
      },
    };
  });

  function formatDate(cell) {
    // return moment(cell).format("YYYY-MM-DD HH:MM");
    return moment(cell).format("MM/DD/YY HH:mm");
  }

  function timezoneformatDate(cell) {
    // return moment(cell).format("YYYY-MM-DD HH:MM");
    return moment(cell).format("MM/DD/YY HH:mm");
  }

  /**
   *@author Sultan
   * @param {*} text
   * @param {*} timezone
   * @returns
   * @description Converts the string of the date message to the required time zone
   * date
   *  17 July 2023
   * messagesToTimezone
   * timezoneformatDateObject
   * timezoneformatDateString
   */
  function messagesToTimezone(text, timezone) {
    let isFirstLine = true;
    let convertedText = text.replace(dateRegex, (match) => {
      //Commented by GK 26-07-2023
      /* if (timezone !== "default") {
          let date = epocToUTC(match);
          let d2 = moments
            .tz(date, "MM/DD/YY HH:mm", timezone)
            .format("MM/DD/YY HH:mm");
          if (isFirstLine) {
            isFirstLine = false;
            return d2; // First line doesn't have "\r\n"
          } else {
            return "\r\n" + d2; // Add "\r\n"  before other lines
          }
        } else {
          if (isFirstLine) {
            isFirstLine = false;
            return "\r\n" + epocToUTCDefault(match); // First line doesn't have "\r\n"
          } else {
            return "\r\n" + epocToUTCDefault(match);
            // Add "\r\n"  before other lines
          }
        } */
      //New code added by GK 26-07-2023
      if (timezone !== "default") {
         let date = epocToUTC(match);
         let d2 = moments
           .tz(date, "MM/DD/YY HH:mm", timezone)
           .format("MM/DD/YY HH:mm");
         if (isFirstLine) {
           isFirstLine = false;
           return d2; // First line doesn't have "\r\n"
         } else {
           return "<br>" + d2; // Add "\r\n"  before other lines
         }
       } else {
         if (isFirstLine) {
           isFirstLine = false;
           return epocToUTCDefault(match); // First line doesn't have "\r\n"
         } else {
           return "<br>" + epocToUTCDefault(match);
           // Add "\r\n"  before other lines
         }
       }
    });
    return convertedText;
  }

  /* //Commented by GK 26-07-2023
    function epocToUTCDefault(cell) {
      let date = moment.utc(+cell).format("MM/DD/YY HH:mm");
      return date;
    }*/
  //New code added by GK 26-07-2023
  function epocToUTCDefaultRes(cell) {
    let currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let date = moments(timezoneformatDate(cell))
      .tz(currenttimezone)
      .format("MM/DD/YY HH:mm");
    return date;
  }

  function epocToUTC(cell) {
    let date = moment.utc(+cell).toDate();
    return date;
  }

  function epocToUTCDefault(cell) {
    //Commented by GK 26-07-2023
    /* let date = moment.utc(+cell).format("MM/DD/YY HH:mm");
      return date; */
    //New code added by GK 26-07-2023
    let currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let date = moments(timezoneformatDate(Number(cell)))
      .tz(currenttimezone)
      .format("MM/DD/YY HH:mm");
    return date;
  }

  // add dateFormatter component. it will convert unix data formate to mm-dd-yyy HH:MM Formated
  function startDateFormatter(cell, row) {
    return (
      <IntlProvider locale="en">
        <div>
          <FormattedDate
            value={cell}
            month="numeric"
            day="numeric"
            year="2-digit"
          />{" "}
          <FormattedTime value={cell} hour12={false} />
        </div>
      </IntlProvider>
    );
  }

  /**
   * @author Sultan Mirza
   * 03-03-2023
   *  added new header as photos_url
   */

  headers = [
    { label: "Incident Number", key: "incident_number" },
    { label: "Type", key: "type" },
    { label: "User language", key: "user_language" },
    { label: "Status", key: "status" },
    { label: "Started D/T", key: "start" },
    { label: "Occurred D/T", key: "occurred" },
    { label: "Sent D/T", key: "sent" },
    { label: "Received D/T", key: "received" },
    { label: "Description", key: "description" },
    { label: "# Photos", key: "num_photos" },
    { label: "Photos links", key: "photos_url" },
    { label: "Location/Line/Route", key: "location" },
    { label: "Lat/Long", key: "gps" },
    { label: "Physical Address", key: "address" },
    { label: "Reporter Name", key: "reporter_name" },
    { label: "Reporter Email", key: "reporter_email" },
    { label: "Reporter Phone", key: "reporter_phone" },
    { label: "Dispatcher Communications", key: "messages" },
    { label: "Dispatcher Ack Time", key: "ack_time" },
    { label: "Dispatcher Log", key: "internal" },
  ];

  var types = {};
  var stats = {};
  var languages = {};
  // data = reports;
  for (var i = 0; i < reports.length; i++) {
    var e = reports[i];

    if (types[e.type] == undefined) {
      types[e.type] = e.type;
    }

    if (stats[e.status] == undefined) {
      stats[e.status] = e.status;
    }

    const language_label = languageLabel(e.user_language);
    languages[language_label] = (languages[language_label] || 0) + 1;

    var desc = e.description;
    if (desc != undefined) {
      e.description = desc.replace(/["']/g, ""); // xt not sure what this is doing. Needs a comment Sultan.  if undefined it is set to [] ?
    }

    var mess = e.messages;
    if (mess != undefined) {
      e.messages = mess.replace(/["']/g, ""); // xt again how is replacing ["'] grabbing [] ?
    }
    const reportSchema = agency.details.report_schemas
      ? agency.details.report_schemas.find(
          (schema) => schema._id === e.report_schema_id
        )
      : [];

    // Get report states for dashboard only
    let reportStates = [];
    let reportStatuses = [];
    let selectedStatus = {};
    let selectedState = {};
    if (undefined !== reportSchema) {
      reportStates = reportSchema.report_states;
      // ? reportSchema.report_states.filter(state => state.types.find(type => type === 'dashboard'))
      // : []

      reportStatuses = reportSchema.report_statuses;
      // Get selected state and status
      selectedState =
        e.state && reportSchema.report_states
          ? reportSchema.report_states.find((state) => state._id === e.state)
          : {};
      selectedStatus =
        selectedState && reportStatuses
          ? reportStatuses.find(
              (status) => status._id === selectedState.report_status_id
            )
          : {};
    }
    e.status = "Closed";

    /* if (selectedStatus.title !== undefined) {
        if (selectedStatus.title[languageCode] !== undefined) {
          e.status = selectedStatus.title[languageCode];
        }
      } */
    reports[i].status = e.status;
    var row = {
      incident_number: e.incident_number,
      type: e.type,
      user_language: languageLabel(e.user_language),
      status: e.status,
      start: timezone
        ? moments(timezoneformatDate(e.start))
            .tz(timezone)
            .format("MM/DD/YY HH:mm")
        : epocToUTCDefaultRes(e.start), //Commented by GK 26-07-2023 epocToUTCDefault(e.start)
      occurred: timezone
        ? moments(timezoneformatDate(e.occurred))
            .tz(timezone)
            .format("MM/DD/YY HH:mm")
        : epocToUTCDefaultRes(e.occurred), //Commented by GK 26-07-2023 epocToUTCDefault(e.occurred)
      received: timezone
        ? moments(timezoneformatDate(e.received))
            .tz(timezone)
            .format("MM/DD/YY HH:mm")
        : epocToUTCDefaultRes(e.received), //Commented by GK 26-07-2023 epocToUTCDefault(e.received)
      sent: timezone
        ? moments(timezoneformatDate(e.sent))
            .tz(timezone)
            .format("MM/DD/YY HH:mm")
        : epocToUTCDefaultRes(e.sent), //Commented by GK 26-07-2023 epocToUTCDefault(e.sent)
      description: e.description,
      gps: e.gps,
      num_photos: e.num_photos,
      // num_photos: e.num_photos == "" ? 0 : e.num_photos.split(",").length,
      photos_url: e.photos_url
        .replace(/"/g, "")
        .split(",")
        .map((link) => {
          return link;
        }),

      location: e.location,
      address: "N/A",
      reporter_name: e.reporter_name,
      reporter_email: e.reporter_email,
      reporter_phone: e.reporter_phone,
      messages: timezone
        ? messagesToTimezone(e.messages, timezone)
        : messagesToTimezone(e.messages, "default"),
      internal: timezone
        ? messagesToTimezone(e.internal, timezone)
        : messagesToTimezone(e.internal, "default"),
      ack_time: e.ack_time,
    };
    data.push(row);
  }
  var languages_stats = {};
  for (let language in languages) {
    languages_stats[language] = language + ` (${languages[language]})`;
  }
  var file_name = "bsg_report_export_" + moment().format("YYYY_MM_DD") + ".csv";
  const tableOptions = {
    defaultSortName: "incident_number",
    defaultSortOrder: "desc",
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",          
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "18px",
            marginLeft: "10px",
            fontWeight: "bold",
          }}
        >
          Total: {""} {count}
        </span>
        {/**
         * @author Sultan
         * 03-03-2023
         * export all data UI and function call.
         */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="exportData-button" onClick={() => handleCsv()}>
            {isFetchingcsv === "fetching" ? "loading..." : "Export All Data "}
          </button>
        </div>
      </div>

      <div className="main-block-b">
        <div className="sidebar">
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              Timezones:
              <Select
                name="form-field-name"
                clearable={false}
                value={timezone}
                onChange={(e) => handleTimezone(e)}
                options={["Select timezone", ...timezones].map((timezone) => ({
                  value: timezone,
                  label: timezone,
                }))}
                // options={languages.map((language) => ({
                //   value: language,
                //   label: languageLabel(language),
                // }))}
              />
            </div>
          </div>
          <div className="sidebar-top-block">
            <span className="top-filter-heading">Filters</span>
            {/**
             * @author Sultan
             * 03-03-2023
             * Its  clear all the existing filter.
             */}
            <span
              className="top-clearAll-heading"
              onClick={() => handleclearAll()}
            >
              Clear All
            </span>
          </div>
          <div className="sidebar-item-block">
            {/**
             * @author Sultan
             * 03-03-2023
             * Filter by date.
             */}
            <div className="item-filter-list">
              <div className="item-top-block">
                <span className="iten-header">{`Date`}</span>
              </div>

              <div
                className="datepicker-output"
                style={{
                  color: "black",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <DatePicker
                    id="startDate11"
                    selected={startDate}
                    onChange={handleStart}
                    placeholderText="Start Date"
                    className="start-datepicker"
                  />
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <DatePicker
                    id="endDate11"
                    selected={endDate}
                    onChange={handleEnd}
                    placeholderText="End Date"
                    className="end-datepicker"
                  />
                </div>
              </div>
              <div className="btn-output" style={{ margin: "20px 0px" }}>
                <Button
                  bsStyle="primary"
                  className="filter-btn"
                  onClick={prepareFetch}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/**
             * @author Sultan
             * 03-03-2023
             * create User language  checkbox UI.
             * onChange handleCheckBoxLanguage function is called and store the user language value
             */}
            <div className="item-filter-list">
              <div className="item-top-block">
                <span className="iten-header">{`User language`}</span>
              </div>
              {["en_US", "es_US"].map((language) => (
                <div className="item-filter-content-b">
                  <input
                    type={"checkbox"}
                    id="1b"
                    style={{ marginRight: "10px" }}
                    onChange={(e) => handleCheckBoxLanguage(e)}
                    value={language}
                    checked={
                      checkboxLanguagevalue.filter((i) => i === language)
                        .length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="1b" className="label-item">
                    {`${language === "en_US" ? "English" : "Spanish"}(${
                      language === "en_US" ? `${en_count}` : `${es_count}`
                    })`}
                  </label>
                  <br />
                </div>
              ))}
            </div>
            {/**
             * @author Sultan
             * 03-03-2023
             * create status checkvbox UI.
             * checkboxStatusvalue is filtering the value and if match then update checked true
             */}
            <div className="item-filter-list">
              <div className="item-top-block">
                <span className="iten-header">Status</span>
              </div>
              {["new", "open", "closed"].map((st) => (
                <div className="item-filter-content-b">
                  <input
                    type={"checkbox"}
                    id="1b"
                    style={{ marginRight: "10px" }}
                    onChange={(e) => handleStatusCheckBox(e)}
                    value={st}
                    checked={
                      checkboxStatusvalue.filter((i) => i === st).length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="1b" className="label-item">
                    {" "}
                    {st.toLocaleUpperCase()}
                  </label>
                  <br />
                </div>
              ))}
            </div>
            {/**
             * @author Sultan
             * 03-03-2023
             * create incident type checkvbox UI.
             * checkboxvalue is filtering the value if match found then checked true
             */}
            <div className="item-filter-list">
              <div className="item-top-block">
                <span className="iten-header">Incident Type</span>
              </div>
              {inc_types.map((st) => (
                <div className="item-filter-content-b">
                  <input
                    type={"checkbox"}
                    id="1b"
                    style={{ marginRight: "10px" }}
                    onChange={(e) => handleCheckBox(e)}
                    value={st}
                    checked={
                      checkboxvalue.filter((i) => i === st).length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="1b" className="label-item">
                    {st}
                  </label>
                  <br />
                </div>
              ))}
            </div>

            {/*  */}
          </div>
        </div>
        <div style={{ display: "flex", width: "83%", flexDirection: "column" }}>
          {/**
           * @author Sultan
           * 03-03-2023
           * added loader
           * if isFetching is true then loader show  loading
           */}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <ClipLoader
              cssOverride={{
                position: "absolute",
                top: "300px",
                left: "auto",
                right: "auto",
                zIndex: "99999",
              }}
              size={50}
              color="black"
              loading={isFetching}
            />
          </div>
         
          {/* <div className="center-block-div"> /*Comment added by GK 09-15-2023 */}

          {/*New style code added to center-block-div to scroll horiz code added by GK 09-15-2023 start*/}

          <div
            className="center-block-div"
            style={{
              height: height,
              overflow: "auto",
            }}
          >
            {/*New code added by GK 09-15-2023 end*/}

            <div className="center-block-container">
              <BootstrapTable
                data={data}
                options={tableOptions}
                // pagination
                hover
                striped
                responsive
              >
                <TableHeaderColumn
                  dataSort={true}
                  dataField="incident_number"
                  isKey={true}
                  // filter={{ type: "TextFilter", delay: 500 }}
                  width="150px" // please leave this 150 xt
                >
                  Incident #
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="type"
                  // filter={{ type: "TextFilter", placeholder: "Type.." }}
                  width="150px"
                >
                  Type
                </TableHeaderColumn>
                {/*  */}
                {/* <TableHeaderColumn
                    filterFormatted
                    dataSort={true}
                    dataField="user_language"
                    filter={{ type: "SelectFilter", options: languages_stats }}
                    width="150px"
                  >
                    User Language
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    filterFormatted
                    dataSort={true}
                    dataField="status"
                    filter={{ type: "SelectFilter", options: stats }}
                    width="130px"
                  >
                    Status
                  </TableHeaderColumn> */}
                {/* add dataFormat Props so we can customize column render */}
                {timezone ? (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="start"
                    // dataFormat={!timezone ? startDateFormatter : ""}
                    width="130px"
                  >
                    Start
                  </TableHeaderColumn>
                ) : (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="start"
                    dataFormat={startDateFormatter}
                    width="130px"
                  >
                    Start
                  </TableHeaderColumn>
                )}
                {timezone ? (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="occurred"
                    width="130px"
                  >
                    Occurred
                  </TableHeaderColumn>
                ) : (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="occurred"
                    dataFormat={startDateFormatter}
                    width="130px"
                  >
                    Occurred
                  </TableHeaderColumn>
                )}

                {timezone ? (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="sent"
                    width="130px"
                  >
                    Sent
                  </TableHeaderColumn>
                ) : (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="sent"
                    dataFormat={startDateFormatter}
                    width="130px"
                  >
                    Sent
                  </TableHeaderColumn>
                )}

                {timezone ? (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="received"
                    width="130px"
                  >
                    Received
                  </TableHeaderColumn>
                ) : (
                  <TableHeaderColumn
                    dataSort={true}
                    dataField="received"
                    dataFormat={startDateFormatter}
                    width="130px"
                  >
                    Received
                  </TableHeaderColumn>
                )}

                <TableHeaderColumn
                  dataSort={true}
                  dataField="description"
                  width="150px"
                  // filter={{ type: "TextFilter", delay: 500 }}
                >
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="num_photos"
                  width="95px"
                >
                  # Photos
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  // dataFormat={nameFormatter}
                  dataField="photos_url"
                  width="405px"
                >
                  Photos Links
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="location"
                  width="180px"
                  // filter={{ type: "TextFilter", delay: 500 }}
                >
                  Location/Line/Route
                </TableHeaderColumn>

                <TableHeaderColumn dataSort={true} dataField="gps">
                  Lat/Long
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="reporter_name"
                  width="145px"
                >
                  Reporter Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="reporter_email"
                  width="130px"
                >
                  Reporter Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="reporter_phone"
                  width="140px"
                >
                  Reporter Phone
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataField="ack_time"
                  width="99px"
                >
                  Ack Time
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataSort={true} dataField="messages">
                    Dispatcher Communications
                  </TableHeaderColumn> */}
                <TableHeaderColumn
                  dataSort={true}
                  dataField="messages"
                  dataFormat={messagesToTimezone}
                >
                  Dispatcher Communications
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataSort={true} dataField="internal">
                    Dispatcher Log
                  </TableHeaderColumn>
                </BootstrapTable> */}
                <TableHeaderColumn
                  dataSort={true}
                  dataField="internal"
                  dataFormat={messagesToTimezone}
                >
                  Dispatcher Log
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
          <div className="center-block-pagination-container">
            {data.length > 0 && (
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <div
                  className="next-prev-div bg-prev"
                  onClick={() => handlePrev(page)}
                >
                  Previous
                </div>
                <div className="page-count-div">
                  <span>Page</span>
                  <input
                    className="input-page"
                    value={page}
                    onChange={(e) => handlePageSize(e)}
                  />
                  <span>of</span>
                  <span>{totalPage}</span>
                </div>
                <div>
                  <Dropdown drop="up">
                    <DropdownToggle id="dropdown-basic">
                      {updatePageRow}
                    </DropdownToggle>

                    <DropdownMenu
                      style={{ minWidth: "70px" }}
                      rootCloseEvent={"mousedown"}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {[10, 20, 30, 50].map((i) => (
                          <button
                            style={{
                              width: "100%",
                              border: "none",
                              borderRadius: "0px",
                            }}
                            onClick={() => handlePageRow(i)}
                          >
                            {i}
                          </button>
                        ))}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div
                  className="next-prev-div bg-next"
                  onClick={() => handleNext(page)}
                >
                  Next
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/**
       * @author Sultan
       * 03-03-2023
       * Export data download Modal.
       * if showLimitModal andd isFetchingcsv value is false then modal will not open
       * handleExportModalClose function close the modal
       */}

      <Modal
        show={
          isFetchingcsv === "receive_data_success" || showLimitModal
            ? true
            : false
        }
        onHide={handleExportModalClose}
        aria-labelledby="example-custom-modal-styling-title"
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "100px",
          height: "100%",
        }}
        size="sm"
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Download Your File
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "120px",
            width: "100%",
          }}
        >
          <div className="text-center" style={{ marginTop: "20px" }}>
            {isFetchingcsv === "receive_data_success" && (
              <CSVLink
              data={csvdata.slice(-500)}
                headers={headers}
                filename={file_name}
                separator={","}
                enclosingCharacter={`"`}
                className="btn btn-primary export-btn csvlink text-center"
                target="_blank"
                onClick={() => {
                  handleExportModalClose();
                }}
              >
                Download
              </CSVLink>
            )}
            <br />
            <p className="text-center" style={{ marginTop: "15px" }}>
              You can download recent most 500 record'(s)
            </p>
            {showLimitModal && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontSize: "18px" }}>
                  <b>Report count :</b> English :{en_count} Spanish: {es_count}{" "}
                  <b>Total</b>: {count}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  Exceeds export limit of 500, refine your search
                </span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => handleExportModalClose()}
            style={{
              cursor: "pointer",
              background: "red",
              border: "none",
              padding: "10px",
              color: "white",
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Reports.propTypes = {
  reports: PropTypes.array.isRequired,
  agency: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
};

export default Reports;
