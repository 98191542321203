/* globals fetch, localStorage */
import Globals from "../utils/globals";
import { logException } from "../utils/AppLogger";
import { getToken, getRequestOptions } from "../utils/ApiHelper";
import { setError } from "./error";

export const REQUEST_REPORT_CSV = "REQUEST_REPORT_CSV";
export const RECEIVE_REPORT_CSV = "RECEIVE_REPORT_CSV";
export const REQUEST_REPORT_FAILURE_CSV = "REQUEST_REPORT_FAILURE_CSV";
export const CLEAR_IS_FETCHING = "CLEAR_IS_FETCHING";
// export const SET_REPORT_VISIBILITY = 'SET_REPORT_VISIBILITY'
/**
 * @author Sultan
 * 03-03-2023
 * create csv action called csvReport.
 */
export const requestReport = (id) => {
  return {
    type: REQUEST_REPORT_CSV,
    agencyId: id,
  };
};
export const clearIsfetching = () => {
  return {
    type: CLEAR_IS_FETCHING,
  };
};

export const receiveReport = (json) => {
  return {
    type: RECEIVE_REPORT_CSV,
    items: json.items,
    totalPage: json.totalPages,
    languages: json.languages,
    current_language: json.current_language,
    receivedAt: Date.now(),
  };
};

export const fetchReportFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: REQUEST_REPORT_FAILURE_CSV,
    error,
  };
};

export const fetchReportCSV = (id, params = {}) => {
  console.log("params csv", params);
  return (dispatch) => {
    const token = getToken();
    let res;
    let fetchUrl;
    // let params
    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    fetchUrl = `${Globals.apiEndpoint}/dashboard/agencies/${id}/reporting`;
    if (Object.keys(params).length) {
      fetchUrl +=
        "?" +
        Object.keys(params)
          .map((queryParam) => `${queryParam}=${params[queryParam]}`)
          .join("&");
    }

    const requestOptions = getRequestOptions(token);

    dispatch(requestReport(id));
    return fetch(fetchUrl, requestOptions)
      .then((response) => {
        res = response;
        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(receiveReport(json));
        }
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};
