import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import agencyStubs from "./agencyStubs";
import agency from "./agency";
import reportSummaries from "./reportSummaries";
import responderLocations from "./responderLocations";
import report from "./report";
import modal from "./modal";
import messages from "./messages";
import predefinedMessages from "./predefinedMessages";
import internalMessages from "./internalMessages";
import profile from "./profile";
import error from "./error";
import reportCSV from "./csvReports";

const rootReducer = combineReducers({
  agencyStubs,
  agency,
  reportSummaries,
  responderLocations,
  report,
  modal,
  messages,
  predefinedMessages,
  internalMessages,
  profile,
  error,
  reportCSV,
  routing: routerReducer,
});

export default rootReducer;
