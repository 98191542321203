import {
  REQUEST_PREDEFINED_MESSAGES,
  RECEIVE_PREDEFINED_MESSAGES,
  REQUEST_PREDEFINED_MESSAGES_FAILURE
} from '../actions/predefinedMessages'

const predefinedMessages = (state = {
  isFetching: false,
  reportId: null,
  detailsList: []
}, action) => {
  switch (action.type) {
    case REQUEST_PREDEFINED_MESSAGES:
      return Object.assign({}, state, {
        isFetching: true,
        reportId: action.reportId
      })
    case RECEIVE_PREDEFINED_MESSAGES:
      return Object.assign({}, state, {
        isFetching: false,
        detailsList: action.detailsList,
        lastUpdated: action.receivedAt
      })
    case REQUEST_PREDEFINED_MESSAGES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    default:
      return state
  }
}

export default predefinedMessages