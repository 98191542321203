import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Dropdown
} from 'react-bootstrap';
import { IntlProvider, FormattedDate, FormattedTime } from 'react-intl';
import NumberFormat from 'react-number-format';
import './Messages.css';
import { getPredefinedMessages } from '../../actions/predefinedMessages'

import $ from 'jquery'
// xt added jquery 120219


class Messages extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollToBottom();
  }


  // xt add next component
  componentDidMount() {


    this.prepareFetch()
  }
  prepareFetch() {
    const { dispatch, agencyId, predefinedMessages, incident: {report_schema_id} } = this.props

      if (!(predefinedMessages && predefinedMessages.detailsList.length)) {
      dispatch(getPredefinedMessages(report_schema_id))
    }
  }
  componentWillReceiveProps(nextProps) {
    const { dispatch, updatePendingMessage, predefinedMessages } = nextProps;

    if (!predefinedMessages.isFetching) {
      if (predefinedMessages.detailsList) { 
        let options = predefinedMessages.detailsList;
        let optionsHtml = ""
        for (let i = 0; i < options.length; i++) {
          optionsHtml += `<h6 class="dropdown-header"><b>${options[i].inc_title}</b></h6>`
          for (let j = 0; j < options[i].inc_type_msg.length; j++) {
            optionsHtml += `<a class="dropdown-item" href="#">${options[i].inc_type_msg[j]}</a>`
          }
        }
        $(".my-dropup-content").html(optionsHtml);
      } else {
        $("#predefined").hide();
      }
      $('.my-dropup .dropdown-item').on('click', function (event) {
        event.preventDefault();
        let selected = $(this).html(); // inner html -- between the tags
        selected = selected.replace(/<br>/gi, "") // this will replace all matches due to the g, the i is no case sensitive
        $('#chat-input-box').val(selected);
        dispatch(updatePendingMessage(selected));
      })
    }
  }

  componentWillUnmount() {
    const { dispatch, clearMessages } = this.props;
    dispatch(clearMessages());
  }

  handleChange(event) {
    const { dispatch, updatePendingMessage } = this.props;

    dispatch(updatePendingMessage(event.target.value));
  }

  handleKeyPress(event) {
    const { addMessage } = this.props;

    if (event.key === 'Enter') {
      addMessage();
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    const { messages } = this.props;

    if (messages) {
      var lastMessage = this[`item-${messages.length - 1}`];
      // carter@foi 3/19/18: changed block: 'end' to block: 'center'. Chat window now properly scrolls to end of chat
      if (lastMessage)
        lastMessage.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  render() {
    const {
      closeTitle,
      incident,
      messages,
      pendingMessage,
      addMessage,
      profile,
      closeChat
    } = this.props;

    return (
      <Grid>
        <Row id="chat-main">
          <Col xs={6} md={3} id="chat-incident-info">
            <div className="incident-gutter">
              {/* <div>{incident.reporter_name}</div>  want to put anonymous if empty*/}
              <div>{incident.reporter_name ? incident.reporter_name : 'name:anonymous'}</div>
              {/* <div>{incident.reporter_email}</div> xt jason mod to put anonymous if empty*/}
              <div>{incident.reporter_email ? incident.reporter_email : 'email:anonymous'}</div>
              <div>
                <NumberFormat
                  value={incident.reporter_phone}
                  format="###-###-####"
                  displayType="text"
                />
                {/* need to ck if the phone number is empty so we can put placeholder */}
                <div>{incident.reporter_phone ? incident.reporter_phone : 'phone:anonymous'}</div>
              </div>
              <div className="spacer20" />
              <div className="summary-display text-medium">
                {incident.display_title}
              </div>
              <div className='text-primary'>
                {incident.title}

                {
                  incident.incident_type_translation && incident.incident_type_translation.en_US && incident.title !== incident.incident_type_translation.en_US && (
                    <div className="text-secondary rounded bg-light border p-1">
                      {incident.incident_type_translation.en_US}
                    </div>
                  )
                }
              </div>
              <div className="spacer20" />
              <div className="text-primary">
                {incident.description}
              </div>
              {
                incident.incident_description_translation && incident.incident_description_translation.en_US && incident.description !== incident.incident_description_translation.en_US && (
                  <div className="text-secondary rounded bg-light border p-1">
                    {incident.incident_description_translation.en_US}
                  </div>
                )
              }
              <div className="spacer" />
              <div className="text-primary">
                {incident.location}
              </div>
              {
                incident.location_description_translation && incident.location_description_translation.en_US && incident.location !== incident.location_description_translation.en_US && (
                  <div className="text-secondary rounded bg-light border p-1">
                    {incident.location_description_translation.en_US}
                  </div>
                )
              }
              <div className="my-dropup">
                <button className="my-dropbtn">Predefined Messages </button>
                <div className="my-dropup-content">
                </div>
              </div>
            </div>
            {closeChat && (
              <Button
                bsStyle="danger"
                bsSize="large"
                className="end-chat"
                onClick={closeChat}
              >
                {closeTitle}
              </Button>
            )}
          </Col>
          <Col xs={8} md={6} id="chat-messages">
            <ListGroup className="chat-thread" id="messages-list">
              {messages.map((msg, idx) => (
                <ListGroupItem
                  key={idx}
                  className={
                    msg.sender_id !== profile.user_id
                      ? 'reporter-item'
                      : 'responder-item'
                  }
                >
                  <div
                    ref={node => {
                      this['item-' + idx] = node;
                    }}
                    id={
                      msg.sender_id !== profile.user_id
                        ? 'reporter-message'
                        : 'responder-message'
                    }
                  >
                    {msg.original_message || msg.message}
                    {
                      msg.message_translation && msg.message_translation.en_US && msg.original_message !== msg.message_translation.en_US && (
                        <div className="text-secondary rounded bg-light border p-1">
                          {msg.message_translation.en_US}
                        </div>
                      )
                    }
                    {
                      msg.message_translation && msg.message_translation.es_US && msg.original_message !== msg.message_translation.es_US && (
                        <div className="text-secondary rounded bg-light border p-1">
                          {msg.message_translation.es_US}
                        </div>
                      )
                    }
                  </div>
                  <IntlProvider locale="en">
                    <div className="chat-meta text-small">
                      <span>{msg.display_name}</span>
                      <FormattedDate value={msg.sent || ''} />
                      <FormattedTime value={msg.sent || ''} />
                    </div>
                  </IntlProvider>
                </ListGroupItem>
              ))}
            </ListGroup>
            <FormGroup id="chat-input">

              {/* <Dropdown drop="up">
                <Dropdown.Toggle variant="success" id="dropdown-basic" bsStyle="danger">
                  Prdefined Messages
              </Dropdown.Toggle>
                <Dropdown.Menu id="predefined">
                </Dropdown.Menu>
              </Dropdown> */}


              {/* <DropdownButton
                drop="up"
                variant="danger"
                title="Predefined Messages"
                id="dropdown-button-drop-up"
              >
                <Dropdown.Item>1</Dropdown.Item>
                <Dropdown.Item>2</Dropdown.Item>
                <Dropdown.Item>3</Dropdown.Item>
              </DropdownButton> */}




              <InputGroup>

                <FormControl
                  id="chat-input-box"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  as="textarea" rows="3" // xt added but not work right
                  // type="text"
                  placeholder="Type Here"
                  autoFocus // carter@foi 3/19/18: added autoFocus to focus the input when modal is triggered
                  value={pendingMessage}
                />
                <InputGroup.Button>
                  <Button
                    onClick={addMessage}
                    bsStyle="default"
                    id="chat-send-button"
                  >
                    Send
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Messages.propTypes = {
  incident: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  hide: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  updatePendingMessage: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    pendingMessage:
      state.messages.pendingMessage ||
      state.internalMessages.pendingMessage ||
      '',
    profile: state.profile.details || { user_id: '' },
    predefinedMessages: state.predefinedMessages || [],
  };
};

export default connect(mapStateToProps)(Messages);
