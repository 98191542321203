import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AuthService from '../../utils/AuthService';
import Globals from '../../utils/globals';
import { setProfile, authCalled } from '../../actions/profile';
import { clearError } from '../../actions/error';
import '../Login/Login.css';

const auth = new AuthService(Globals.auth0Client, Globals.auth0Domain);

class Callback extends Component {
  render() {
    return <Redirect to="/" />;
  }
}

export default Callback;
