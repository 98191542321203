/* globals localStorage, fetch */
import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

export const REQUEST_RESPONDER_LOCATIONS = 'REQUEST_RESPONDER_LOCATIONS'
export const RECEIVE_RESPONDER_LOCATIONS = 'RECEIVE_RESPONDER_LOCATIONS'
export const REQUEST_RESPONDER_LOCATIONS_FAILURE = 'REQUEST_RESPONDER_LOCATIONS_FAILURE'

export const requestResponderLocations = () => {
  return {
    type: REQUEST_RESPONDER_LOCATIONS
  }
}

export const receiveResponderLocations = (json) => {
  return {
    type: RECEIVE_RESPONDER_LOCATIONS,
    responderLocations: json,
    receivedAt: Date.now()
  }
}

export const fetchResponderLocationsFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_RESPONDER_LOCATIONS_FAILURE,
    error
  }
}

export const fetchResponderLocations = (agencyId) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)

    dispatch(requestResponderLocations())

    return fetch(`${Globals.apiEndpoint}/dashboard/agencies/${agencyId}/current_responder_locations`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveResponderLocations(json))
        }
      })
      .catch(error => {
        dispatch(fetchResponderLocationsFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}
