import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import AuthService from '../../utils/AuthService'
import Globals from '../../utils/globals'
import { invalidateAgency } from '../../actions/agencyStubs'
import '../Login/Login.css'

const auth = new AuthService(Globals.auth0Client, Globals.auth0Domain)

class Logout extends React.Component {
  componentWillMount () {
    const { dispatch } = this.props
    if (auth.loggedIn()) {
      dispatch(invalidateAgency())
      auth.logout()
    }
  }

  render () {
    return (
     <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    agencyStubs: state.agencyStubs
  }
}

export default connect(mapStateToProps)(Logout)
