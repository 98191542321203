import React from 'react'
import PropTypes from 'prop-types'
import { ListGroup } from 'react-bootstrap'

import Agency from './Agency'

const AgencyList = ({ agencies, languageCode, pathRef }) => {
  return (
    <ListGroup id='agency-list'>
      {agencies.map(agency =>
        <Agency
          key={agency._id}
          pathRef={pathRef}
          agency={agency}
          languageCode={languageCode}
        />
      )}
    </ListGroup>
  )
}

AgencyList.propTypes = {
  agencies: PropTypes.array.isRequired,
  pathRef: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired
}

export default AgencyList
