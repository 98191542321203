// 08-15-21 xt:  added agencyStubs.lastUpdated code per vickie to make the console wait for agency stubs to populate.
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, PageHeader } from "react-bootstrap";
import Sound from "react-sound";
import NewItemTone from "../../assets/sounds/app-tone.mp3";
import WebChatChime from "../../assets/sounds/webchatchime.mp3";

// Services
import LocalizationService from "../../utils/LocalizationService";

// Utilities
import { getAgencyStatusMap } from "../../utils/ObjectFilters";

// Actions
import { requestStubs, selectAgencyId } from "../../actions/agencyStubs";
import { fetchAgency } from "../../actions/agency";
import {
  fetchReportSummaries,
  dismissNotification,
  clearNewItem,
  setReportVisibility,
  reportSummariesTabStatus,
} from "../../actions/reportSummaries";

// Components
import AgencyList from "../../components/Agencies/AgencyList";
import ReportSummaries from "../../components/ReportSummaries/ReportSummaries";
import ModalRoot from "../../components/Modal/ModalRoot";
import Notification from "../Notification/Notification";
import StateTabs from "../StateTabs/StateTabs";

// Selectors
import { getVisibleReportSummaries } from "../../selectors/reportSummaries";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    /**
     *  Taking two state for pageSize and page
     */
    this.state = {
      rowPerPage: 10,
      curPage: 0,
      newAndOpen: 0,
      new: 0,
      open: 0,
      closed: 0,
      chatting: 0,
      tabStatus: "",
    };
    this.dismiss = this.dismiss.bind(this);
    this.startPoll = this.startPoll.bind(this);
    this.handleToneFinished = this.handleToneFinished.bind(this);
    this.toggleStateVisibility = this.toggleStateVisibility.bind(this);
    this.prepareFetch = this.prepareFetch.bind(this);
    this.updateCurPage = this.updateCurPage.bind(this); // binding the update page funtionality
    this.updateRowPerPage = this.updateRowPerPage.bind(this); // binding the update pageSize funtionality
  }

  componentWillReceiveProps(nextProps) {
    const { agencyStubs, reportSummaries, dispatch, match } = nextProps;

    if (!agencyStubs.selectedAgencyId) {
      const agencyId = match.params.id || null;

      if (agencyId) {
        dispatch(selectAgencyId(agencyId));
      } else if (agencyStubs.items.length === 1) {
        dispatch(selectAgencyId(agencyStubs.items[0]._id));
      }
    }

    if (this.props.reportSummaries) {
      if (this.props.reportSummaries.items !== reportSummaries.items) {
        clearTimeout(this.timeout);

        if (!nextProps.reportSummaries.isFetching) {
          this.startPoll(agencyStubs.selectedAgencyId);
        }
      }
    }
  }

  componentWillMount() {
    const { agencyStubs, agency, dispatch } = this.props;

    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length === 0) {
      dispatch(requestStubs());
    } else if (agencyStubs.selectedAgencyId) {
      if (!agency.details._id)
        dispatch(fetchAgency(agencyStubs.selectedAgencyId)).then(() => {
          this.prepareFetch(agencyStubs.selectedAgencyId);
        });
    }
    this.prepareFetch(agencyStubs.selectedAgencyId);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentWillUpdate(nextProps, nextState) {
    const { agencyStubs, dispatch } = this.props;

    if (
      agencyStubs.selectedAgencyId !== nextProps.agencyStubs.selectedAgencyId
    ) {
      dispatch(fetchAgency(nextProps.agencyStubs.selectedAgencyId)).then(() => {
        this.prepareFetch(nextProps.agencyStubs.selectedAgencyId);
      });
    }
  }

  prepareFetch(agencyId, filter) {
    const { dispatch, agency, reportSummaries } = this.props;
    let filterIds = [];
    let chattingFilter = false;
    const filterState = filter || reportSummaries.visibilityFilter;
    if (filterState !== "SHOW_ALL" && filterState !== "SHOW_CHATTING") {
      const statusName = filterState.substring(filterState.indexOf("_") + 1);
      const statusMap = getAgencyStatusMap(agency.details, statusName);
      filterIds = statusMap.report_statuses;
    }

    if (filterState === "SHOW_CHATTING") chattingFilter = true;
    // let curPageData =
    //   this.state.tabStatus == "SHOW_New & Open"
    //     ? this.state.newAndOpen
    //     : this.state.tabStatus == "SHOW_New"
    //     ? this.state.new
    //     : this.state.tabStatus == "SHOW_Open"
    //     ? this.state.open
    //     : this.state.tabStatus == "SHOW_Closed"
    //     ? this.state.closed
    //     : this.state.tabStatus == "SHOW_CHATTING"
    //     ? this.state.chatting
    //     : this.state.curPage;
    dispatch(
      fetchReportSummaries(
        agencyId,
        filterIds,
        chattingFilter,
        this.state.rowPerPage,
        // curPageData,
        this.state.curPage,
        this.state.tabStatus
      )
    );
  }

  dismiss() {
    this.props.dispatch(dismissNotification());
  }

  startPoll(agencyId) {
    this.timeout = setTimeout((filter) => {
      this.prepareFetch(agencyId);
    }, 10000);
  }

  handleToneFinished() {
    this.props.dispatch(clearNewItem());
  }
  /**
   * @author Sultan
   * 03-03-2023
   * set tab status after page load.
   */
  componentDidMount() {
    const { agencyStubs, agency, dispatch } = this.props;
    this.setState({
      tabStatus: this.state.tabStatus
        ? this.state.tabStatus
        : "SHOW_New & Open",
    });

    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length === 0) {
      dispatch(requestStubs());
    } else if (agencyStubs.selectedAgencyId) {
      if (!agency.details._id)
        dispatch(fetchAgency(agencyStubs.selectedAgencyId)).then(() => {
          this.prepareFetch(agencyStubs.selectedAgencyId);
        });
    }

    // this.prepareFetch(this.props.agency.agencyId);
  }
  /**
   * @author Sultan
   * 03-03-2023
   * added tab status after tab change.
   */
  toggleStateVisibility(event) {
    this.props.dispatch(reportSummariesTabStatus(event));

    if (event == "SHOW_New & Open") {
      this.setState(
        {
          curPage: this.state.newAndOpen,
        },
        () => {
          this.props.dispatch(setReportVisibility(event));
          this.prepareFetch(this.props.agency.agencyId, event);
        }
      );
    }
    if (event == "SHOW_New") {
      this.setState(
        {
          curPage: this.state.new,
        },
        () => {
          this.props.dispatch(setReportVisibility(event));
          this.prepareFetch(this.props.agency.agencyId, event);
        }
      );
    }
    if (event == "SHOW_Open") {
      this.setState(
        {
          curPage: this.state.open,
        },
        () => {
          this.props.dispatch(setReportVisibility(event));
          this.prepareFetch(this.props.agency.agencyId, event);
        }
      );
    }
    if (event == "SHOW_Closed") {
      this.setState(
        {
          curPage: this.state.closed,
        },
        () => {
          this.props.dispatch(setReportVisibility(event));
          this.prepareFetch(this.props.agency.agencyId, event);
        }
      );
    }
    if (event == "SHOW_CHATTING") {
      this.setState(
        {
          curPage: this.state.chatting,
        },
        () => {
          this.props.dispatch(setReportVisibility(event));
          this.prepareFetch(this.props.agency.agencyId, event);
        }
      );
    }
  }
  /**
   * Create pageSize (updateRowPerPage) and page (updateCurPage) function for the update of the state.
   * When update state we do an api call for the get report summaries
   */
  updateRowPerPage(pageSize) {
    this.setState(
      {
        rowPerPage: pageSize,
      },
      () => {
        this.prepareFetch(this.props.agency.agencyId);
      }
    );
  }

  updateCurPage(page) {
    const { tabStatusDetails } = this.props;
    if (tabStatusDetails === "SHOW_New & Open") {
      this.setState(
        {
          newAndOpen: page,
          curPage: page,
        },
        () => {
          this.prepareFetch(this.props.agency.agencyId);
        }
      );
    }
    if (tabStatusDetails === "SHOW_Open") {
      this.setState(
        {
          open: page,
          curPage: page,
        },
        () => {
          this.prepareFetch(this.props.agency.agencyId);
        }
      );
    }
    if (tabStatusDetails === "SHOW_New") {
      this.setState(
        {
          new: page,
          curPage: page,
        },
        () => {
          this.prepareFetch(this.props.agency.agencyId);
        }
      );
    }
    if (tabStatusDetails === "SHOW_Closed") {
      this.setState(
        {
          closed: page,
          curPage: page,
        },
        () => {
          this.prepareFetch(this.props.agency.agencyId);
        }
      );
    }
    if (tabStatusDetails === "SHOW_CHATTING") {
      this.setState(
        {
          chatting: page,
          curPage: page,
        },
        () => {
          this.prepareFetch(this.props.agency.agencyId);
        }
      );
    }

    // this.setState(
    //   {
    //     curPage: page,
    //   },
    //   () => {
    //     this.prepareFetch(this.props.agency.agencyId);
    //   }
    // );
    // this.setState(
    //   {
    //     curPage:
    //       this.state.tabStatus == "SHOW_New & Open"
    //         ? this.state.newAndOpen
    //         : this.state.tabStatus == "SHOW_New"
    //         ? this.state.new
    //         : this.state.tabStatus == "SHOW_Open"
    //         ? this.state.open
    //         : this.state.tabStatus == "SHOW_Closed"
    //         ? this.state.closed
    //         : this.state.tabStatus == "SHOW_CHATTING"
    //         ? this.state.chatting
    //         : this.state.curPage,
    //   },
    //   () => {
    // this.prepareFetch(this.props.agency.agencyId);
    //   }
    //   );
  }
  render() {
    const {
      agencyStubs,
      agency,
      reportSummaries,
      error,
      languageCode,
      profile,
      totalPages,
      isFetching,
      tabStatusDetails,
    } = this.props;

    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length > 0) {
      return (
        <AgencyList
          pathRef="/dashboard"
          agencies={agencyStubs.items}
          languageCode={languageCode}
        />
      );
    }

    const lastUserReceived = [];
    reportSummaries.items.forEach((item) => {
      if (item.chat_summary) {
        lastUserReceived.push(
          item.chat_summary ? item.chat_summary.user_last_received : null
        );
      }
    });

    lastUserReceived.sort((a, b) => {
      return b - a;
    });

    let latestUser =
      lastUserReceived.length > 0 ? `${lastUserReceived[0]}` : null;
    let localUser = localStorage.highestUserReceived;

    if (
      latestUser > localUser ||
      (latestUser != null && localUser === "undefined")
    ) {
      localStorage.setItem("highestUserReceived", latestUser);
    }

    return (
      <Grid>
        {reportSummaries.lastUpdatedField && (
          <Row>
            <Notification
              text="Incident Updated!"
              type="success"
              onDismiss={this.dismiss}
              show={!!reportSummaries.lastUpdatedField}
            />
          </Row>
        )}
        {error.isActive && (
          <Row>
            <Notification
              text={`Error: ${error.message}`}
              type="danger"
              onDismiss={this.dismiss}
              show={error.isActive}
            />
          </Row>
        )}
        <Row>
          <Col>
            {reportSummaries.newItem.type === "public" &&
              reportSummaries.newItem.isNew && (
                <Sound
                  url={NewItemTone}
                  playStatus={
                    reportSummaries.newItem.type === "public" &&
                    reportSummaries.newItem.isNew
                      ? "PLAYING"
                      : "STOPPED"
                  }
                  onFinishedPlaying={this.handleToneFinished}
                />
              )}
            {latestUser > localUser && latestUser !== "0" && (
              <Sound
                url={WebChatChime}
                playStatus={
                  latestUser > localUser && latestUser !== "0"
                    ? "PLAYING"
                    : "STOPPED"
                }
              />
            )}

            <PageHeader>
              {agency.details.name ? agency.details.name[languageCode] : null}
            </PageHeader>
          </Col>
        </Row>
        <Row>
          {agency.details.display_pages && (
            <StateTabs
              toggleStateVisibility={this.toggleStateVisibility}
              agency={agency}
              languageCode={languageCode}
              tabStatusDetails={tabStatusDetails}
            />
          )}
        </Row>
        <Row>
          <ReportSummaries
            dispatch={this.props.dispatch}
            summaries={reportSummaries.items}
            totalPages={reportSummaries.totalPages}
            newItem={reportSummaries.newItem}
            agency={agency}
            languageCode={languageCode}
            profile={profile}
            rowPerPage={this.state.rowPerPage}
            curPage={this.state.curPage}
            updateRowPerPage={this.updateRowPerPage}
            updateCurPage={this.updateCurPage}
          />
          <ModalRoot />
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencyStubs: state.agencyStubs || [],
    agency: state.agency || {},
    reportSummaries: getVisibleReportSummaries(state) || [],
    error: state.error || null,
    totalPages: state.totalPages || 0,
    isFetching: state.reportSummaries.isFetching,
    tabStatusDetails: state.reportSummaries.tabStatus,
    languageCode: LocalizationService.getCurrentLanguage(),
    profile: state.profile || {},
  };
};

export default connect(mapStateToProps)(Dashboard);
