/* globals fetch, localStorage */
import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

export const REQUEST_AGENCY_STUBS = 'REQUEST_AGENCY_STUBS'
export const RECEIVE_AGENCY_STUBS = 'RECEIVE_AGENCY_STUBS'
export const SELECT_AGENCY_ID = 'SELECT_AGENCY_ID'
export const INVALIDATE_AGENCY = 'INVALIDATE_AGENCY'
export const REQUEST_AGENCY_STUBS_FAILURE = 'REQUEST_AGENCY_STUBS_FAILURE'

export const requestAgencyStubs = () => {
  return {
    type: REQUEST_AGENCY_STUBS
  }
}

export const receiveAgencyStubs = (json) => {
  // console.log(json);
  return {
    type: RECEIVE_AGENCY_STUBS,
    agencyStubs: json,
    receivedAt: Date.now()
  }
}

export const selectAgencyId = (agencyId) => {
  return {
    type: SELECT_AGENCY_ID,
    agencyId
  }
}

export const invalidateAgency = () => {
  return {
    type: INVALIDATE_AGENCY,
    agencyId: null
  }
}

export const fetchAgencyStubsFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_AGENCY_STUBS_FAILURE,
    error
  }
}

export const requestStubs = () => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)
    console.log(`${Globals.apiEndpoint}/dashboard/agency_stubs`);
    // console.log('request-options',requestOptions)
    dispatch(requestAgencyStubs())

    return fetch(`${Globals.apiEndpoint}/dashboard/agency_stubs`, requestOptions)
      .then(response => {
        res = response
        // console.log(response.json());
        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveAgencyStubs(json))
        }
      })
      .catch(error => {
        dispatch(fetchAgencyStubsFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}
