import React from 'react'
import { connect } from 'react-redux'
import { showModal, hideModal, updateModalProps } from '../../actions/modal'
import { postUnitRoleAndShiftIncidentAssignment } from '../../actions/reportSummaries'
import { Modal, ListGroup, ListGroupItem, FormGroup, FormControl, InputGroup, Glyphicon } from 'react-bootstrap'

const SelectUnitModal = ({
  agency,
  incident,
  units,
  dispatch,
  visible,
  languageCode
}) => {
  const assignedDepartment = incident.assignments[0].assignee.department_id
  const unitsMaster = agency.assignees.units.filter(
    unit => unit.departments.find(
      department => department === assignedDepartment))

  const hide = () => dispatch(hideModal())
  const saveSelection = (assignmentId) => {
    dispatch(hideModal())

    if (agency.assignees.shifts) {
      const shiftModalProps = {
        agency,
        incident,
        unitId: assignmentId,
        reportSchemaId: incident.report_schema_id,
        shifts: agency.assignees.shifts,
        languageCode
      }

      dispatch(showModal('SELECT_SHIFT', shiftModalProps))
    } else {
      dispatch(postUnitRoleAndShiftIncidentAssignment(incident, agency._id, incident.report_schema_id, assignmentId))
    }
  }

  const filterList = (event) => {
    var updatedList = unitsMaster

    updatedList = updatedList.filter((item) => {
      return item.name[languageCode].toLowerCase().search(
        event.target.value.toLowerCase()) !== -1
    })

    dispatch(updateModalProps('units', updatedList))
  }

  return (
    <Modal show={visible} onHide={hide} id='unit-modal' dialogClassName='assignment-modal' backdrop>
      <Modal.Header closeButton closeLabel='Cancel'>
        <Modal.Title>Select Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <InputGroup id='unit-search-input'>
            <InputGroup.Addon><Glyphicon glyph='search' /></InputGroup.Addon>
            <FormControl id='role-search' type='text' placeholder='Search' onChange={filterList} />
          </InputGroup>
        </FormGroup>
        <ListGroup className='text-medium'>
          {units.filter(unit => unit.departments.find(department => department === assignedDepartment)).map(unit =>
            <ListGroupItem key={unit._id} onClick={() => saveSelection(unit._id)}>
              <div className='department-name'>{unit.name[languageCode]}</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default connect()(SelectUnitModal)
