import {
  REQUEST_INTERNAL_MESSAGES,
  RECEIVE_INTERNAL_MESSAGES,
  REQUEST_INTERNAL_MESSAGES_FAILURE,
  UPDATE_PENDING_INTERNAL_MESSAGE,
  CLEAR_PENDING_INTERNAL_MESSAGE,
  CLEAR_INTERNAL_MESSAGES,
  ADD_INTERNAL_MESSAGE,
  ADD_INTERNAL_MESSAGE_FAILURE,
  ACK_ADD_INTERNAL_MESSAGE
} from '../actions/internalMessages'

const getMessages = (msgs, msg) => {
  return [
    ...msgs,
    msg
  ]
}

const internalMessages = (state = {isFetching: false,
  isUpdating: false,
  incidentId: null,
  agencyId: null,
  reportSchemaId: null,
  messages: []
}, action) => {
  switch (action.type) {
    case REQUEST_INTERNAL_MESSAGES:
      return {
        ...state,
        isFetching: true,
        incidentId: action.incidentId,
        agencyId: action.agencyId,
        reportSchemaId: action.reportSchemaId
      }
    case RECEIVE_INTERNAL_MESSAGES:
      return {
        ...state,
        isFetching: false,
        messages: action.internalMessages,
        lastUpdated: action.receivedAt
      }
    case CLEAR_INTERNAL_MESSAGES:
      return {
        isFetching: false,
        isUpdating: false,
        incidentId: null,
        agencyId: null,
        reportSchemaId: null,
        messages: []
      }
    case REQUEST_INTERNAL_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case UPDATE_PENDING_INTERNAL_MESSAGE: {
      return {
        ...state,
        isUpdating: false,
        pendingMessage: action.message
      }
    }
    case CLEAR_PENDING_INTERNAL_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        pendingMessage: null
      }
    case ACK_ADD_INTERNAL_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        result: action.result
      }
    case ADD_INTERNAL_MESSAGE:
      return {
        ...state,
        isUpdating: true,
        messages: getMessages(state.messages, action.message)
      }
    case ADD_INTERNAL_MESSAGE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error
      }
    default:
      return state
  }
}

export default internalMessages
