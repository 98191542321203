/* actions/reportSummaries.js */

/* globals fetch, localStorage */
import Globals from "../utils/globals";
import { logException } from "../utils/AppLogger";
import { getToken, getRequestOptions } from "../utils/ApiHelper";
import { setError } from "./error";

// Report Summaries
export const REQUEST_REPORT_SUMMARIES = "REQUEST_REPORT_SUMMARIES";
export const RECEIVE_REPORT_SUMMARIES = "RECEIVE_REPORT_SUMMARIES";
export const REQUEST_REPORT_SUMMARIES_FAILURE =
  "REQUEST_REPORT_SUMMARIES_FAILURE";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const CLEAR_NEW_ITEM = "CLEAR_NEW_ITEM";
export const SET_REPORT_VISIBILITY = "SET_REPORT_VISIBILITY";
export const DELETE_INCIDENT = "DELETE_INCIDENT";
// Assignments
export const UPDATE_INCIDENT_DEPARTMENT = "UPDATE_INCIDENT_ASSIGNMENT";
export const ADD_INCIDENT_UNIT_ROLE_AND_SHIFT =
  "ADD_INCIDENT_UNIT_ROLE_AND_SHIFT";
export const ACK_UPDATE_INCIDENT_ASSIGNMENT = "ACK_INCIDENT_ASSIGNMENT";
export const UPDATE_INCIDENT_ASSIGNMENT_FAILURE =
  "UPDATE_INCIDENT_ASSIGNMENT_FAILURE";
export const DELETE_INCIDENT_ASSIGNMENT = "DELETE_INCIDENT_ASSIGNMENT";
export const ACK_DELETE_INCIDENT_ASSIGNMENT = "ACK_DELETE_INCIDENT_ASSIGNMENT";
export const DELETE_INCIDENT_ASSIGNMENT_FAILURE =
  "DELETE_INCIDENT_ASSIGNMENT_FAILURE";
// Tags
export const UPDATE_TAG = "UPDATE_TAG";
export const ACK_UPDATE_TAG = "ACK_UPDATE_TAG";
export const UPDATE_TAG_FAILURE = "UPDATE_TAG_FAILURE";
// State & Status
export const UPDATE_INCIDENT_STATE = "UPDATE_INCIDENT_STATE";
export const UPDATE_INCIDENT_STATUS = "UPDATE_INCIDENT_STATUS";
export const ACK_UPDATE_INCIDENT_STATE = "ACK_UPDATE_INCIDENT_STATE";
export const UPDATE_INCIDENT_STATE_FAILURE = "UPDATE_INCIDENT_STATE_FAILURE";
// Close Chat
export const CLOSE_CHAT = "CLOSE_CHAT";
export const ACK_CLOSE_CHAT = "ACK_CLOSE_CHAT";
export const CLOSE_CHAT_FAILURE = "CLOSE_CHAT_FAILURE";
export const TAB_STATUS = "TAB_STATUS";

export const requestReportSummaries = (id) => {
  return {
    type: REQUEST_REPORT_SUMMARIES,
    agencyId: id,
  };
};
export const reportSummariesTabStatus = (value) => {
  return {
    type: TAB_STATUS,
    tab_status: value,
  };
};

export const receiveReportSummaries = (json) => {
  return {
    type: RECEIVE_REPORT_SUMMARIES,
    items: json,
    totalPages: json.totalPages,
    receivedAt: Date.now(),
  };
};

export const dismissNotification = () => {
  return {
    type: DISMISS_NOTIFICATION,
    lastUpdatedField: null,
  };
};

export const clearNewItem = () => {
  return {
    type: CLEAR_NEW_ITEM,
  };
};

export const setReportVisibility = (visibility) => {
  return {
    type: SET_REPORT_VISIBILITY,
    visibility,
  };
};

export const updateIncidentDepartment = (
  incidentId,
  agencyId,
  reportSchemaId,
  value
) => {
  return {
    type: UPDATE_INCIDENT_DEPARTMENT,
    incidentId,
    agencyId,
    reportSchemaId,
    field: "department_id",
    value,
  };
};

export const updateTag = (incidentId, agencyId, reportSchemaId, value) => {
  return {
    type: UPDATE_TAG,
    incidentId,
    agencyId,
    reportSchemaId,
    field: "tag",
    value,
  };
};

export const postIncidentAssignmentFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: UPDATE_INCIDENT_ASSIGNMENT_FAILURE,
    error,
  };
};

export const ackPostAssignment = (incidentId, field, json) => {
  return {
    type: ACK_UPDATE_INCIDENT_ASSIGNMENT,
    incidentId,
    lastUpdatedField: field,
    lastUpdatedResult: json,
  };
};

export const removeIncidentAssignment = (incidentId, assignmentId) => {
  return {
    type: DELETE_INCIDENT_ASSIGNMENT,
    incidentId,
    assignmentId,
  };
};

export const ackDeleteIncidentAssignment = (incidentId, json) => {
  return {
    type: ACK_DELETE_INCIDENT_ASSIGNMENT,
    incidentId,
    lastUpdatedField: "assignments",
    lastUpdatedResult: json,
  };
};

export const deleteIncident = (incidentId) => {
  return {
    type: DELETE_INCIDENT,
    incidentId,
  };
};

export const deleteIncidentAssignmentFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: DELETE_INCIDENT_ASSIGNMENT_FAILURE,
    error,
  };
};

export const deleteReport = (incidentId, agencyId, reportSchemaId) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "DELETE",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
    };

    dispatch(deleteIncident(incidentId));
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}`,
      requestOptions
    )
      .then((response) => {
        res = response;
        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackDeleteIncidentAssignment(incidentId, json));
        }
      })
      .catch((error) => {
        dispatch(deleteIncidentAssignmentFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const deleteIncidentAssignment = (
  incidentId,
  agencyId,
  reportSchemaId,
  assignmentId
) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "DELETE",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
    };

    dispatch(removeIncidentAssignment(incidentId, assignmentId));
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/current_assignments/${assignmentId}`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackDeleteIncidentAssignment(incidentId, json));
        }
      })
      .catch((error) => {
        dispatch(deleteIncidentAssignmentFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const addIncidentUnitRoleAndShift = (
  incidentId,
  agencyId,
  reportSchemaId,
  unitId,
  roleId,
  shiftId,
  timelessStart
) => {
  return {
    type: ADD_INCIDENT_UNIT_ROLE_AND_SHIFT,
    incidentId,
    agencyId,
    reportSchemaId,
    unitId,
    roleId,
    shiftId,
    timelessStart,
  };
};

export const postUnitRoleAndShiftIncidentAssignment = (
  incident,
  agencyId,
  reportSchemaId,
  unitId,
  roleId,
  shiftId,
  startDate
) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "POST",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assignee: {
          ...incident.assignments[0].assignee,
          unit_id: unitId,
          role_id: roleId,
          shift_id: shiftId,
        },
        sent: Date.now(),
        timeless_start: startDate,
      }),
    };

    dispatch(
      addIncidentUnitRoleAndShift(
        incident._id,
        agencyId,
        reportSchemaId,
        unitId,
        roleId,
        shiftId,
        startDate
      )
    );
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${
        incident._id
      }/assignments`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(
            ackPostAssignment(
              incident._id,
              ["unit_id", "role_id", "shift_id"],
              json
            )
          );
        }
      })
      .catch((error) => {
        dispatch(postIncidentAssignmentFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const postDepartmentIncidentAssignment = (
  incident,
  agencyId,
  reportSchemaId,
  value
) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "POST",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assignee: {
          department_id: value,
        },
        sent: Date.now(),
      }),
    };

    dispatch(
      updateIncidentDepartment(incident._id, agencyId, reportSchemaId, value)
    );
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${
        incident._id
      }/assignments`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackPostAssignment(incident._id, "department_id", json));
        }
      })
      .catch((error) => {
        dispatch(postIncidentAssignmentFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const putIncidentTagFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: UPDATE_TAG_FAILURE,
    error,
  };
};

export const ackPutTag = (incidentId, json) => {
  return {
    type: ACK_UPDATE_TAG,
    incidentId,
    lastUpdatedField: "tag",
    lastUpdatedResult: json,
  };
};

export const putIncidentTag = (incidentId, agencyId, reportSchemaId, value) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "PUT",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tag: value,
      }),
    };

    dispatch(updateTag(incidentId, agencyId, reportSchemaId, value));
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/tag`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackPutTag(incidentId, json));
        }
      })
      .catch((error) => {
        dispatch(putIncidentTagFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const updateIncidentState = (
  incidentId,
  agencyId,
  reportSchemaId,
  value
) => {
  return {
    type: UPDATE_INCIDENT_STATE,
    incidentId,
    agencyId,
    reportSchemaId,
    field: "state",
    value,
  };
};

export const updateIncidentStatus = (incidentId, value) => {
  return {
    type: UPDATE_INCIDENT_STATUS,
    incidentId,
    field: "status_id",
    value,
  };
};

export const ackUpdateIncidentState = (incidentId, json) => {
  return {
    type: ACK_UPDATE_INCIDENT_STATE,
    incidentId,
    lastUpdatedField: "state",
    lastUpdatedResult: json,
  };
};

export const postIncidentStateFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: UPDATE_INCIDENT_STATE_FAILURE,
    error,
  };
};

export const postIncidentState = (
  incidentId,
  agencyId,
  reportSchemaId,
  value
) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "POST",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        report_state_id: value,
        sent: Date.now(),
      }),
    };

    dispatch(updateIncidentState(incidentId, agencyId, reportSchemaId, value));
    return fetch(
      `${
        Globals.apiEndpoint
      }/agencies/${agencyId}/report_schema/${reportSchemaId}/reports/${incidentId}/report_state_values`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackUpdateIncidentState(incidentId, json));
        }
      })
      .catch((error) => {
        dispatch(postIncidentStateFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};
export const fetchReportSummariesFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: REQUEST_REPORT_SUMMARIES_FAILURE,
    error,
  };
};

export const fetchReportSummaries = (
  id,
  statusFilterIds = [],
  chattingFilter = false,
  pageSize,
  curPage,
  tabStatus
) => {
  return (dispatch) => {
    const token = getToken();
    let res;
    let fetchUrl;
    console.log(
      "in fetchReportSummaries the statusFilterIds is: ",
      statusFilterIds.length
    );
    console.log(
      " in actions/reportSummaries at the creation of the fetchUrl checking statusFilterIds.length: ",
      statusFilterIds
    );
    if (chattingFilter) {
      fetchUrl = `${
        Globals.apiEndpoint
      }/dashboard/agencies/${id}/report_summaries?chat_status=open&pageSize=${pageSize}&page=${curPage}`;
    } else {
      fetchUrl = `${
        Globals.apiEndpoint
      }/dashboard/agencies/${id}/report_summaries?pageSize=${pageSize}&page=${curPage}`;
      if (statusFilterIds.length > 0) {
        console.log(
          "we are at statusFilterIds.length > 0 line.",
          statusFilterIds.length
        );
        fetchUrl = `${fetchUrl}&status_ids=${statusFilterIds.join(",")}`;
      } else
        console.log(
          "we are at statusFilterIds.length is 0 :",
          statusFilterIds.length
        );
    }

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const requestOptions = getRequestOptions(token);

    dispatch(requestReportSummaries(id));
    return fetch(fetchUrl, requestOptions)
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(receiveReportSummaries(json));
        }
      })
      .catch((error) => {
        dispatch(fetchReportSummariesFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};

export const setChatStatusToClosed = (incidentId, agencyId, reportSchemaId) => {
  return {
    type: CLOSE_CHAT,
    incidentId,
    agencyId,
    reportSchemaId,
  };
};

export const ackCloseChat = (json) => {
  return {
    type: ACK_CLOSE_CHAT,
    result: json,
    receivedAt: Date.now(),
  };
};

export const closeChatFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem("profile"),
  });

  return {
    type: CLOSE_CHAT_FAILURE,
    error,
  };
};

export const putCloseChat = (incidentId, agencyId, reportSchemaId) => {
  return (dispatch) => {
    const token = getToken();
    let res;

    if (!token)
      return dispatch(
        setError(
          null,
          "Token not found",
          "No API token available. Please log in again."
        )
      );

    const defaulRequestOptions = getRequestOptions(token);
    const requestOptions = {
      method: "PUT",
      headers: {
        ...defaulRequestOptions.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: "closed",
      }),
    };

    dispatch(setChatStatusToClosed(incidentId, agencyId, reportSchemaId));
    return fetch(
      `${
        Globals.apiEndpoint
      }/dashboard/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}/chat_summary/status`,
      requestOptions
    )
      .then((response) => {
        res = response;

        return response.json();
      })
      .then((json) => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message));
        } else {
          dispatch(ackCloseChat(json));
        }
      })
      .catch((error) => {
        dispatch(closeChatFailure(error));
        dispatch(setError(null, "Network Error", error.message));
      });
  };
};
