import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import AuthService from '../../utils/AuthService'
// Components
import Login from '../Login/Login'
import Logout from '../Logout/Logout'

// Views
import Dashboard from '../../views/Dashboard/Dashboard'
import Callback from '../Callback/Callback'
import Incidents from '../../views/Incidents/Incidents'
import Chat from '../Chat/Chat'
import Notes from '../Notes/Notes'
import Responders from '../../views/Responders/Responders'
import Error from '../../views/Error/Error'

// new reports - carter foi - 3/28/18
import Reports from '../../views/Reports/Reports'

import PrivateRoute from '../Auth/PrivateRoute'

const auth2 = new AuthService();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth2.handleAuthentication();
  }
}

const Main = () =>
  <main>
    <Switch>
    <Route exact path='/dashboard/agencies/:agencyId/report_schemas/:reportSchemaId/reports/:reportId/chats' component={Chat} />
    <Route exact path='/dashboard/agencies/:agencyId/report_schemas/:reportSchemaId/reports/:reportId/notes' component={Notes}  />
      <PrivateRoute exact path='/' component={Dashboard} auth={auth2} />
      <PrivateRoute path='/dashboard/:id' component={Dashboard} auth={auth2} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} auth={auth2} />
      <PrivateRoute exact path='/incidents' component={Incidents} auth={auth2} />
      <PrivateRoute exact path='/reports' component={Reports} auth={auth2} />
      <PrivateRoute path='/responders/:id' component={Responders} auth={auth2} />
      <PrivateRoute exact path='/responders' component={Responders} auth={auth2} />
      <Route path='/callback' render={(props) => {
        handleAuthentication(props);
        return <Callback {...props} /> 
      }}/>
      <Route exact path='/login' component={Login} />
      <Route exact path='/logout' component={Logout} />
      <Route component={Error} />
    </Switch>
  </main>

Main.propTypes = {
  // auth: PropTypes.instanceOf(AuthService),

}


export default Main
