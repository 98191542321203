export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS'

export const showModal = (type, props) => {
  return {
    type: SHOW_MODAL,
    modalType: type,
    modalProps: props
  }
}

export const updateModalProps = (field, props) => {
  return {
    type: UPDATE_MODAL_PROPS,
    field: field,
    props: props
  }
}

export const hideModal = () => {
  return {
    type: HIDE_MODAL
  }
}
