/* 20230306: There was no header on this file as a work history so I am starting one now
*            EVERY PROGRAMMER WILL KEEP THIS HEADER LOG UP TO DATE EVERY TIME THIS CODE IS CHANGED!
* 20230305: XT - I am putting the Spanish translation code back into the front end that was lost or removed.
*            The html changes for Spanish should be in THREE columns; incident, description & location description
* 20230307: XT - found bug in the colors of font for the images and map if 0 images or map not set. 
*             the <div> HAS to be separated because they are in levels. Top level is BLACK and the onClick makes it blue/link
* 20230801: xt - fixed the spanish translation to show for the MDT GO app
*                and fixed the frontend to show the spanish translation for when the assigment col shows
*/

import React, { useState } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { Label } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "react-table/react-table.css";
import "./ReportSummaries.css";

import IncidentTimes from "../Incidents/IncidentTimes";
import IncidentResponder from "../Incidents/IncidentResponder";
import IncidentAction from "../Incidents/IncidentAction";
import { showModal } from "../../actions/modal";

const ReportSummaries = ({
  dispatch,
  summaries,
  agency,
  languageCode,
  profile,
  rowPerPage,
  curPage,
  updateRowPerPage,
  updateCurPage,
  totalPages,
}) => {
  let noDept = false;
  if (
    undefined !== agency.details.assignees &&
    agency.details.assignees.departments.length > 0
  ) {
    noDept = true;
  } else {
    noDept = false;
  }

  const showAssignmentCol =
    profile.details.app_metadata.assignment_column != null
      ? profile.details.app_metadata.assignment_column
      : false;

  const showImages = (photos) => {
    const incidentImagesProps = {
      images: photos,
    };

    dispatch(showModal("INCIDENT_IMAGES", incidentImagesProps));
  };

  const showMap = (incident) => {
    const incidentMapProps = {
      incident,
    };

    dispatch(showModal("INCIDENT_MAP", incidentMapProps));
  };

  const getUnitAssignmentTime = (assignments) => {
    let assignmentTime;

    for (let assignment of assignments) {
      if (assignment.assignee.unit_id) {
        assignmentTime = assignment.received;
        break;
      }
    }

    return assignmentTime;
  };

  // console.log("summaries", summaries);
  // console.log("totalPages", totalPages);

  if (noDept && showAssignmentCol) {
    return (
      <div>
        <ReactTable
          table
          data={summaries}
          columns={[
            {
              Header: "Incident",
              className: "incident-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  <div className="summary-display text-medium">
                    {row.original.display_title}
                  </div>
                  <div className="summary-title text-primary">
                    {row.original.title}
                  </div>
                  {/* Code above formats the text blue as I asked. Code below is used for the display of the title in the English Language in text box below the spanish if there is spanish language */}
                  {row.original.incident_type_translation &&
                    row.original.incident_type_translation.en_US &&
                    row.original.title !==
                      row.original.incident_type_translation.en_US && (
                      <div className="summary-title text-secondary rounded bg-light border p-1">
                        {row.original.incident_type_translation.en_US}
                      </div>
                    )}
                  <IncidentTimes
                    started={row.original.started}
                    occurred={row.original.occurred}
                    received={row.original.received}
                    deptAssigned={
                      row.original.assignments.length > 0
                        ? row.original.assignments[0].received
                        : null
                    }
                    unitAssigned={getUnitAssignmentTime(
                      row.original.assignments
                    )}
                  />
                </div>
              ),
            },
            {
              Header: "Description",
              id: "description",
              className: "description-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  <div className="text-primary">
                    {row.original.description}
                  </div>
                  {row.original.incident_description_translation &&
                    row.original.incident_description_translation.en_US &&
                    row.original.description !==
                      row.original.incident_description_translation.en_US && (
                      <div className="text-secondary rounded bg-light border p-1">
                        {row.original.incident_description_translation.en_US}
                      </div>
                    )}
                  <div className="description">
                    {row.original.photos.length > 0 && (
                      <a
                        tabIndex="-1"
                        onClick={showImages.bind(this, row.original.photos)}
                      >
                        <i className="material-icons">photo</i>
                        <span className="image-text">
                          {`${row.original.photos.length} Images`}
                        </span>
                      </a>
                    )}
                    {row.original.photos.length === 0 && (
                      <span>
                        <i className="material-icons">photo</i>
                        <span className="image-text">
                          {`${row.original.photos.length} Images`}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              ),
            },
            {
              Header: "Location",
              accessor: "location",
              className: "location-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                <div className="incident-location text-primary">
                    {row.original.location}
                  </div>
                   {row.original.location_description_translation &&
                    row.original.location_description_translation.en_US &&
                    row.original.location !==
                      row.original.location_description_translation.en_US && (
                      <div className="incident-location text-secondary rounded bg-light border p-1">
                        {row.original.location_description_translation.en_US}
                      </div>
                    )}
                  <div className="location">
                    {row.original.coordinate && (
                      <a
                        tabIndex="-1"
                        onClick={showMap.bind(this, row.original)}
                      >
                        <i className="material-icons">add_location</i>
                        <span className="image-text">Show Map</span>
                      </a>
                    )}
                    {!row.original.coordinate && (
                      <span>
                        <i className="material-icons">add_location</i>
                        <span className="image-text">No Map Coordinates Given</span>
                      </span>
                    )}
                  </div>
                </div>
              ),
            },
            {
              Header: "Reporter Info",
              accessor: "reporter_name",
              className: "reporter-column",
              Cell: (row) => (
                <div>
                  {row.original.reporter_contact_allowed && (
                    <div className="fix-whitespace">
                      <div>{row.original.reporter_name}</div>
                      <div>{row.original.reporter_email}</div>
                      <div>
                        <NumberFormat
                          value={row.original.reporter_phone}
                          format="###-###-####"
                          displayType="text"
                        />
                      </div>
                    </div>
                  )}
                  {!row.original.reporter_contact_allowed && (
                    <div>
                      <Label bsStyle="danger" bsSize="small">
                        Do Not Contact
                      </Label>
                    </div>
                  )}
                </div>
              ),
            },
            {
              Header: "Assignment",
              accessor: "reporter_name",
              className: "responder-column",
              Cell: (row) => (
                <div>
                  {
                    <IncidentResponder
                      incident={row.original}
                      agency={agency.details}
                      languageCode={languageCode}
                    />
                  }
                </div>
              ),
            },
            {
              Header: "Action",
              accessor: "state",
              className: "action-column",
              Cell: (row) => (
                <div>
                  <IncidentAction
                    incident={row.original}
                    agency={agency}
                    reportSchemaId={row.original.report_schema_id}
                    languageCode={languageCode}
                    profile={profile}
                  />
                </div>
              ),
            },
          ]}
          // Sultan - Whenever there is a change in the page and pageSize we are updating the state
          className="-striped -highlight -responsive"
          defaultPageSize={rowPerPage}
          onPageChange={(page) => updateCurPage(page)}
          onPageSizeChange={(pageSize, page) => {
            updateRowPerPage(pageSize);
          }}
          page={curPage}
          pages={totalPages}
          manual
        />
      </div>
    );
  } else {
    return (
      <div>
        <ReactTable
          table
          data={summaries}
          columns={[
            {
              Header: "Incident",
              className: "incident-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  <div className="summary-display text-medium">
                    {row.original.display_title}
                  </div>
                  <div className="summary-title text-primary">
                    {row.original.title}
                  </div>
                  {/* Code above formats the text blue as I asked. Code below is used for the display of the title in the English Language in text box below the spanish if there is spanish language */}
                  {row.original.incident_type_translation &&
                    row.original.incident_type_translation.en_US &&
                    row.original.title !==
                      row.original.incident_type_translation.en_US && (
                      <div className="summary-title text-secondary rounded bg-light border p-1">
                        {row.original.incident_type_translation.en_US}
                      </div>
                    )}
                  <IncidentTimes
                    started={row.original.started}
                    occurred={row.original.occurred}
                    received={row.original.received}
                    deptAssigned={
                      row.original.assignments.length > 0
                        ? row.original.assignments[0].received
                        : null
                    }
                    unitAssigned={getUnitAssignmentTime(
                      row.original.assignments
                    )}
                  />
                </div>
              ),
            },
            {
              Header: "Description",
              id: "description",
              className: "description-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  <div className="text-primary">
                    {row.original.description}
                  </div>
                  {row.original.incident_description_translation &&
                    row.original.incident_description_translation.en_US &&
                    row.original.description !==
                      row.original.incident_description_translation.en_US && (
                      <div className="text-secondary rounded bg-light border p-1">
                        {row.original.incident_description_translation.en_US}
                      </div>
                    )}
                  <div className="description">
                    {row.original.photos.length > 0 && (
                      <a
                        tabIndex="-1"
                        onClick={showImages.bind(this, row.original.photos)}
                      >
                        <i className="material-icons">photo</i>
                        <span className="image-text">
                          {`${row.original.photos.length} Images`}
                        </span>
                      </a>
                    )}
                    {row.original.photos.length === 0 && (
                      <span>
                        <i className="material-icons">photo</i>
                        <span className="image-text">
                          {`${row.original.photos.length} Images`}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              ),
            },
            {
              Header: "Location",
              accessor: "location",
              className: "location-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  <div className="incident-location text-primary">
                    {row.original.location}
                  </div>
                  {row.original.location_description_translation &&
                    row.original.location_description_translation.en_US &&
                    row.original.location !==
                      row.original.location_description_translation.en_US && (
                      <div className="incident-location text-secondary rounded bg-light border p-1">
                        {row.original.location_description_translation.en_US}
                      </div>
                    )}
                  <div className="location">
                    {row.original.coordinate && (
                      <a
                        tabIndex="-1"
                        onClick={showMap.bind(this, row.original)}
                      >
                        <i className="material-icons">add_location</i>
                        <span className="image-text">Show Map</span>
                      </a>
                    )}
                    {!row.original.coordinate && (
                      <span>
                        <i className="material-icons">add_location</i>
                        <span className="image-text">No Map Coordinates Given</span>
                      </span>
                    )}
                  </div>
                </div>
              ),
            },
            {
              Header: "Reporter Info",
              accessor: "reporter_name",
              className: "reporter-column",
              Cell: (row) => (
                <div className="fix-whitespace">
                  {row.original.reporter_contact_allowed && (
                    <div>
                      <div>{row.original.reporter_name}</div>
                      <div>{row.original.reporter_email}</div>
                      <div>
                        <NumberFormat
                          value={row.original.reporter_phone}
                          format="###-###-####"
                          displayType="text"
                        />
                      </div>
                    </div>
                  )}
                  {!row.original.reporter_contact_allowed && (
                    <div>
                      <Label bsStyle="danger" bsSize="small">
                        Do Not Contact
                      </Label>
                    </div>
                  )}
                </div>
              ),
            },
            {
              Header: "Action",
              accessor: "state",
              className: "action-column",
              Cell: (row) => (
                <div>
                  <IncidentAction
                    incident={row.original}
                    agency={agency}
                    reportSchemaId={row.original.report_schema_id}
                    languageCode={languageCode}
                    profile={profile}
                  />
                </div>
              ),
            },
          ]}
          // Sultan - Whenever there is a change in the page and pageSize we are updating the state
          className="-striped -highlight -responsive"
          defaultPageSize={rowPerPage}
          onPageChange={(page) => updateCurPage(page)}
          onPageSizeChange={(pageSize, page) => {
            updateRowPerPage(pageSize);
          }}
          page={curPage}
          pages={totalPages}
          manual
        />
      </div>
    );
  }

  /// return (
  //   <span>
  //     <Table striped hover responsive className="incident-table">
  //       <thead>
  //         <tr>
  //           <th>Incident</th>
  //           <th>Description</th>
  //           <th>Location</th>
  //           <th>Reporter Info</th>
  //           {noDept && <th>Responder</th>}
  //           <th>Action</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {summaries.map((summary, index) =>
  //           <tr key={index}>
  //             <td className='incident-column'>
  //               <div className='summary-display text-medium'>{summary.display_title}</div>
  //               <div className='summary-title'>{summary.title}</div>
  //               <IncidentTimes
  //                 started={summary.started}
  //                 occurred={summary.occurred}
  //                 received={summary.received}
  //                 deptAssigned={summary.assignments.length > 0 ? summary.assignments[0].received : null}
  //                 unitAssigned={getUnitAssignmentTime(summary.assignments)}
  //               />
  //             </td>
  //             <td className='description-column'>
  //               <div>{summary.description}</div>
  //               <div className='description'>
  //                 { summary.photos.length > 0 &&
  //                   <a tabIndex='-1' onClick={showImages.bind(this, summary.photos)}>
  //                     <i className='material-icons'>photo</i>
  //                     <span className='image-text'>
  //                       {`${summary.photos.length} Images`}
  //                     </span>
  //                   </a>
  //                 }
  //                 { summary.photos.length === 0 &&
  //                   <span>
  //                     <i className='material-icons'>photo</i>
  //                     <span className='image-text'>
  //                       {`${summary.photos.length} Images`}
  //                     </span>
  //                   </span>
  //                 }
  //               </div>
  //             </td>
  //             <td className='location-column'>
  //               <div>{summary.location}</div>
  //               <div className='location'>
  //                 { summary.coordinate &&
  //                   <a tabIndex='-1' onClick={showMap.bind(this, summary)}>
  //                     <i className='material-icons'>add_location</i>
  //                     <span className='image-text'>
  //                       Show Map
  //                     </span>
  //                   </a>
  //                 }
  //                 { !summary.coordinate &&
  //                   <span>
  //                     <i className='material-icons'>add_location</i>
  //                     <span className='image-text'>
  //                       Show Map
  //                     </span>
  //                   </span>
  //                 }
  //               </div>
  //             </td>
  //             <td className='reporter-column'>
  //               { summary.reporter_contact_allowed &&
  //                 <div>
  //                   <div>{summary.reporter_name}</div>
  //                   <div>{summary.reporter_email}</div>
  //                   <div><NumberFormat value={summary.reporter_phone} format='###-###-####' displayType='text' /></div>
  //                 </div>
  //               }
  //               { !summary.reporter_contact_allowed &&
  //                 <div>
  //                   <Label bsStyle='danger' bsSize='small'>Do Not Contact</Label>
  //                 </div>
  //               }
  //             </td>
  //               {noDept &&  <IncidentResponder
  //               incident={summary}
  //               agency={agency.details}
  //               languageCode={languageCode} />}
  //             <IncidentAction
  //               incident={summary}
  //               agency={agency}
  //               reportSchemaId={summary.report_schema_id}
  //               languageCode={languageCode}
  //               profile={profile} />
  //           </tr>
  //         )}
  //       </tbody>
  //     </Table>
  //   </span>
  // )
};

ReportSummaries.propTypes = {
  summaries: PropTypes.array.isRequired,
  agency: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
};

export default ReportSummaries;
