import React, { Component } from 'react'
import auth0 from 'auth0-js';
import Globals from '../../utils/globals'
// import PropTypes from 'prop-types'
import { showModal } from '../../actions/modal'
import { connect } from 'react-redux'
import ModalRoot from '../Modal/ModalRoot'
import { getToken, getRequestOptions } from '../../utils/ApiHelper'
import decode from 'jwt-decode'
import axios from 'axios'

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incident: {}
    }
  }


  componentWillMount() {
  const { match, location } = this.props
  const agencyId = match.params.agencyId
  const incidentId = match.params.reportId
  const reportSchemaId = match.params.reportSchemaId
  let splitUriString = location.search.split('&')
  let encodedUser = splitUriString[0].split('=')
  let encodedPass = splitUriString[1].split('=')
  const username = decodeURIComponent(encodedUser[1])
  const password = decodeURIComponent(encodedPass[1])


  const webAuth = new auth0.WebAuth({
    domain: Globals.auth0Domain,
    clientID: Globals.auth0Client,
    responseType: 'token'
  });
  webAuth.client.login({
    realm: 'Username-Password-Authentication',
    username: username,
    password: password,
  }, (err, result) => {

    if (result) {
      localStorage.setItem("id_token", result.idToken)
      console.log("token success");
      const token = getToken()
      const requestOptions = getRequestOptions(token)
      const decoded = decode(token)
      localStorage.setItem("profile", JSON.stringify(decoded))


      axios(`${Globals.apiEndpoint}/dashboard/agencies/${agencyId}/report_schemas/${reportSchemaId}/reports/${incidentId}`, requestOptions)
      .then(response => {
        this.setState({incident: response.data}, () => {
          this.connectChat()
        })
      })
    }
    if (err) {
      console.log("access denied");
    }
  })
}

connectChat() {
  const { dispatch, match } = this.props
  const agencyId = match.params.agencyId
  const reportSchemaId = match.params.reportSchemaId
  const incidentChatProps = {
    incident: this.state.incident,
    agencyId,
    reportSchemaId
  }
dispatch(showModal('FULL_INCIDENT_CHAT', incidentChatProps))

}





  render() {
    if(!this.state.incident){
     return null; //Or some other replacement component or markup
  }

  return (
    <div>
        <ModalRoot />
    </div>
  );

}
}

// /dashboard/agencies/594650907884dd0011d952a2/report_schemas/5947364d0a1020001138de51/reports/59d74b26d7bd00001262c6d0/chats



export default connect()(Chat)
