/* globals fetch, localStorage */
import Globals from '../utils/globals'
import { logException } from '../utils/AppLogger'
import { getToken, getRequestOptions } from '../utils/ApiHelper'
import { setError } from './error'

export const REQUEST_AGENCY = 'REQUEST_AGENCY'
export const RECEIVE_AGENCY = 'RECEIVE_AGENCY'
export const REQUEST_AGENCY_FAILURE = 'REQUEST_AGENCY_FAILURE'

export const requestAgency = (id) => {
  return {
    type: REQUEST_AGENCY,
    agencyId: id
  }
}

export const receiveAgency = (json) => {
  return {
    type: RECEIVE_AGENCY,
    details: json,
    receivedAt: Date.now()
  }
}

export const fetchAgencyFailure = (error) => {
  logException(error, {
    profile: localStorage.getItem('profile')
  })

  return {
    type: REQUEST_AGENCY_FAILURE,
    error
  }
}

export const fetchAgency = (id) => {
  return dispatch => {
    const token = getToken()
    let res

    if (!token) return dispatch(setError(null, 'Token not found', 'No API token available. Please log in again.'))

    const requestOptions = getRequestOptions(token)
    // console.log('request-options',requestOptions)
    dispatch(requestAgency(id))
// XT THIS IS PULLING IN ALL THE AGENCY JSON AND THE REPORT SCHEMA AT THIS ENDPOINT!!!
    return fetch(`${Globals.apiEndpoint}/dashboard/agencies/${id}`, requestOptions)
      .then(response => {
        res = response

        return response.json()
      })
      .then(json => {
        if (res.status !== 200) {
          return dispatch(setError(res.status, res.statusText, json.message))
        } else {
          dispatch(receiveAgency(json))
        }
      })
      .catch(error => {
        dispatch(fetchAgencyFailure(error))
        dispatch(setError(null, 'Network Error', error.message))
      })
  }
}
