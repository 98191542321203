/* globals window */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { hideModal } from '../../actions/modal'
import { fetchReport } from '../../actions/report'
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap'
import { IntlProvider, FormattedDate, FormattedTime } from 'react-intl'
import Map from '../../components/Map/Map'
import './IncidentResourceModal.css'

class IncidentResourceModal extends Component {
  constructor (props) {
    super(props)

    Moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: '%s',
        s: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    })

    this.hide = this.hide.bind(this)
    this.getReportCoords = this.getReportCoords.bind(this)
    this.getStateValueCoords = this.getStateValueCoords.bind(this)
    this.getCoords = this.getCoords.bind(this)
    this.getMapCenterCoords = this.getMapCenterCoords.bind(this)
  }

  componentDidMount () {
      const { dispatch, incident } = this.props

          dispatch(fetchReport(incident.agency_id, incident.report_schema_id, incident._id))
  }

  hide () {
    const { dispatch } = this.props

    dispatch(hideModal())
  }

  getReportCoords() {
    const {
      report,
      agency,
      languageCode,
      incident
    } = this.props

    let agencySchema = agency.report_schemas.find((schema) => schema._id === incident.report_schema_id)
    let defaultStateId = agencySchema.default_state_id
    let newReportState = agencySchema.report_states.find((state) => state._id === defaultStateId)
    let reportCoordinates = [{title: newReportState.title[languageCode], markerColor: newReportState.color}]

    if(report && report.coordinate){

      let {latitude, longitude} = report.coordinate

  reportCoordinates.coordinates = {
    lat: latitude,
    lng: longitude
  }

   }

   return reportCoordinates
  }

  getStateValueCoords() {
    const {
      report,
      agency,
      languageCode,
      incident
    } = this.props
    let agencySchema = agency.report_schemas.find((schema) => schema._id === incident.report_schema_id)
    let reportStateValues = report.report_state_values || []

    let stateValueCoords = []

    reportStateValues.map(stateValue => {
      if (stateValue.coordinate) {

      let stateInfo = agencySchema.report_states.find((state) => state._id === stateValue.report_state_id)
        stateValueCoords.push({
          coordinates: {
            lat: stateValue.coordinate.latitude,
            lng: stateValue.coordinate.longitude
          },
          title: stateInfo.title[languageCode],
          markerColor: stateInfo.color,
          received: stateValue.received,
        })
        console.log(stateValueCoords, 'I am the StateValue Function returning my coords');

    }

    return stateValueCoords
  })
  }

  getMapCenterCoords() {
    const {agency} = this.props
    let agencyMapCenter = agency.map_center
    let mapCenterCoords = {
      coordinates: {
        lat: agencyMapCenter.latitude,
        lng: agencyMapCenter.longitude
      }
    }
    return mapCenterCoords

  }

  getCoords () {
    const {
      report
    } = this.props

    let coords = []

    //in the case there is no report use last resort coords
    if(report === undefined || (report.coordinate === undefined && report.report_state_values === undefined)) {
      console.log("No Report")
      let result = this.getMapCenterCoords()
      coords.push(result)
    } else {
      //There is a Report to get data from
      if (report.coordinate === undefined && report.report_state_values.length <= 0){
        console.log("No Report Coordinates, No Report State Values")
        let result = this.getMapCenterCoords()
        coords.push(result)
      } else if (report.coordinate) {
        console.log("Report Coordinates")
        let result = this.getReportCoords()
        coords.push(result)
      } else if (report.coordinate === undefined && report.report_state_values.length > 0) {
          console.log("No Report Coordinates, Report State Values")
        //let result = this.getStateValueCoords()
          let result = this.getMapCenterCoords()
        coords.push(result)
      } else {
          console.log("In final else")
      }
    }
    console.log(coords)
    return coords
  }

  render () {
    let {
      incident,
      report,
      agency,
      visible,
      languageCode
    } = this.props

    let agencySchema = agency.report_schemas.find((schema) => schema._id === incident.report_schema_id)
    let reportStateValues = report.report_state_values || []
    let defaultStateId = agencySchema.default_state_id
    let newReportState = agencySchema.report_states.find((state) => state._id === defaultStateId)
    let defaultReceived = Moment(report.received)

    return (
      <Modal show={visible} onHide={this.hide} id='resource-modal' bsSize='large' backdrop>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='material-icons'>check_circle</i>
            <span className='image-text'>History - {incident.display_title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='resource-modal-body'>
          <div id='map'>
            <Map coords={this.getCoords()} />

          </div>
          <IntlProvider locale='en'>
            <ListGroup className='text-medium resource-list'>
              {reportStateValues
                .sort((first, second) => first.received > second.received ? -1 : 1)
                .map((stateValue, index) => {
                  const reportState = agencySchema.report_states.find((state) => state._id === stateValue.report_state_id)
                  const received = Moment(stateValue.received)
                  const elapsedTime = index === reportStateValues.length - 1
                    ? null
                    : received.to(reportStateValues[index + 1].received)

                  return (
                    <ListGroupItem key={index}>
                      <svg height='40px' width='40px'>
                        <circle cx='20' cy='20' r='18' style={{fill: reportState.color}} />
                      </svg>
                      <div className='resource-display'>
                        <div className='resource-name'>{reportState.title[languageCode]}</div>
                        <div className='history-times text-small'>
                          <FormattedDate value={received} month='numeric' day='numeric' year='2-digit' />
                          <FormattedTime value={received} hour12={false} />
                        </div>
                      </div>
                      <div className='elapsed-time text-small'>{elapsedTime}</div>
                    </ListGroupItem>
                  )
                }
              )}
              <ListGroupItem>
                <svg height='40px' width='40px'>
                  <circle cx='20' cy='20' r='18' style={{fill: newReportState.color}} />
                </svg>
                <div className='resource-display'>
                  <div className='resource-name'>{newReportState.title[languageCode]}</div>
                  <div className='history-times text-small'>
                    <FormattedDate value={defaultReceived} month='numeric' day='numeric' year='2-digit' />
                    <FormattedTime value={defaultReceived} hour12={false} />
                  </div>
                </div>
              </ListGroupItem>

            </ListGroup>
          </IntlProvider>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    report: state.report.report || {},
    agency: state.agency.details || {}
  }
}

export default connect(mapStateToProps)(IncidentResourceModal)
