// 08-15-21  xt:   cahnged the default visibility filter to the New a& Open from the SHOW_ALL and not use the ALL tab.
//           xt: the ALL tab was causing too many records to be loaded to the console and caused timeouts.
import _ from "lodash";

import {
  REQUEST_REPORT_SUMMARIES,
  RECEIVE_REPORT_SUMMARIES,
  REQUEST_REPORT_SUMMARIES_FAILURE,
  DISMISS_NOTIFICATION,
  CLEAR_NEW_ITEM,
  SET_REPORT_VISIBILITY,
  UPDATE_INCIDENT_DEPARTMENT,
  ADD_INCIDENT_UNIT_ROLE_AND_SHIFT,
  ACK_UPDATE_INCIDENT_ASSIGNMENT,
  UPDATE_INCIDENT_ASSIGNMENT_FAILURE,
  DELETE_INCIDENT_ASSIGNMENT,
  ACK_DELETE_INCIDENT_ASSIGNMENT,
  DELETE_INCIDENT_ASSIGNMENT_FAILURE,
  UPDATE_TAG,
  ACK_UPDATE_TAG,
  UPDATE_TAG_FAILURE,
  UPDATE_INCIDENT_STATE,
  UPDATE_INCIDENT_STATUS,
  ACK_UPDATE_INCIDENT_STATE,
  UPDATE_INCIDENT_STATE_FAILURE,
  CLOSE_CHAT,
  ACK_CLOSE_CHAT,
  TAB_STATUS,
} from "../actions/reportSummaries";

const resetAssignmentItemsWithDepartment = (items, action) => {
  const index = _.findIndex(items, (item) => item._id === action.incidentId);

  return [
    ...items.slice(0, index),
    {
      ...items[index],
      assignments: [
        {
          assignee: {
            department_id: action.value,
          },
        },
      ],
    },
    ...items.slice(index + 1),
  ];
};

const getAssignmentItemsWithUnitRoleAndShift = (items, action) => {
  const index = _.findIndex(items, (item) => item._id === action.incidentId);
  const currentAssignments = items[index].assignments;
  let assignments;

  if (currentAssignments.length > 0) {
    if (currentAssignments[currentAssignments.length - 1].assignee.unit_id) {
      // Create new assignment and copy department_id
      assignments = [
        ...currentAssignments,
        {
          _id: "123",
          assignee: {
            department_id: currentAssignments[0].assignee.department_id,
            unit_id: action.unitId,
            role_id: action.roleId,
            shift_id: action.shiftId,
          },
          timeless_start: action.timelessStart,
        },
      ];
    } else {
      // Add Unit and role to existing assignment
      assignments = [
        {
          ...currentAssignments[currentAssignments.length - 1],
          assignee: {
            ...items[index].assignments[currentAssignments.length - 1].assignee,
            unit_id: action.unitId,
            role_id: action.roleId,
            shift_id: action.shiftId,
          },
          timeless_start: action.timelessStart,
        },
      ];
    }
  } else {
    assignments = [
      {
        assignee: {
          ...items[index].assignments[0].assignee,
          unit_id: action.unitId,
          role_id: action.roleId,
          shift_id: action.shiftId,
        },
        timeless_start: action.timelessStart,
      },
    ];
  }

  return [
    ...items.slice(0, index),
    {
      ...items[index],
      assignments,
    },
    ...items.slice(index + 1),
  ];
};

const getItems = (items, action) => {
  const index = _.findIndex(items, (item) => item._id === action.incidentId);

  return [
    ...items.slice(0, index),
    { ...items[index], [action.field]: action.value },
    ...items.slice(index + 1),
  ];
};

const getItemsForChat = (items, action) => {
  const index = _.findIndex(items, (item) => item._id === action.incidentId);

  return [
    ...items.slice(0, index),
    {
      ...items[index],
      chat_summary: {
        ...items[index].chat_summary,
        status: "closed",
      },
    },
    ...items.slice(index + 1),
  ];
};

const reduceItems = (items, action) => {
  let reducedItems = [];

  items.forEach((item) => {
    const index = _.findIndex(
      item.assignments,
      (assignment) => assignment._id === action.assignmentId
    );

    reducedItems.push({
      ...item,
      assignments: [
        ...item.assignments.slice(0, index),
        ...item.assignments.slice(index + 1),
      ],
    });
  }, this);

  return reducedItems;
};

const hasNewReport = (state, fetchedItems) => {
  let newReport = false;
  const stateItems = state.items;
  if (!stateItems && fetchedItems.length !== 0) {
    return {
      isNew: true,
      lastUpdated: Date.now(),
    };
  }
  const newItem =
    state.newItem && fetchedItems
      ? fetchedItems.findIndex(
          (item) => item.received > state.newItem.lastUpdated
        )
      : -1;

  if (newItem > -1) newReport = true;

  return {
    isNew: newReport,
    lastUpdated: newReport
      ? Date.now()
      : state.newItem
      ? state.newItem.lastUpdated
      : null,
    type: fetchedItems.length > 0 ? fetchedItems[0].type : null,
  };
};

const reportSummaries = (
  state = {
    isFetching: false,
    isUpdating: false,
    newItem: {
      isNew: false,
      lastUpdated: null,
      type: "",
    },
    agencyId: null,
    items: [],
    lastUpdatedField: null,
    lastUpdatedResult: null,
    visibilityFilter: "SHOW_New & Open", // xt: this means that every agency NEEDS to have this
    //visibilityFilter: 'SHOW_ALL'
    tabStatus: "SHOW_New & Open",
  },
  action
) => {
  switch (action.type) {
    case REQUEST_REPORT_SUMMARIES:
      return {
        ...state,
        isFetching: true,
        agencyId: action.agencyId,
      };
    case RECEIVE_REPORT_SUMMARIES:
      return {
        ...state,
        isFetching: false,
        newItem: hasNewReport(state, action.items.data),
        items: action.items.data,
        totalPages: action.items.totalPages,
        lastUpdated: action.receivedAt,
      };
    case DISMISS_NOTIFICATION:
      return {
        ...state,
        lastUpdatedField: null,
        lastUpdatedResult: null,
      };
    case CLEAR_NEW_ITEM:
      return {
        ...state,
        newItem: {
          ...state.newItem,
          isNew: false,
        },
      };
    case SET_REPORT_VISIBILITY:
      return {
        ...state,
        visibilityFilter: action.visibility,
      };
    case UPDATE_INCIDENT_DEPARTMENT:
      return {
        ...state,
        isUpdating: true,
        items: resetAssignmentItemsWithDepartment(state.items, action),
      };
    case ADD_INCIDENT_UNIT_ROLE_AND_SHIFT:
      return {
        ...state,
        isUpdating: true,
        items: getAssignmentItemsWithUnitRoleAndShift(state.items, action),
      };
    case DELETE_INCIDENT_ASSIGNMENT:
      return {
        ...state,
        isUpdating: true,
        items: reduceItems(state.items, action),
      };
    case CLOSE_CHAT:
      return {
        ...state,
        isUpdating: true,
        items: getItemsForChat(state.items, action),
      };
    case ACK_CLOSE_CHAT:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_TAG:
    case UPDATE_INCIDENT_STATE:
      return {
        ...state,
        isUpdating: true,
        items: getItems(state.items, action),
      };
    case UPDATE_INCIDENT_STATUS:
      return {
        ...state,
        items: getItems(state.items, action),
      };
    case ACK_UPDATE_INCIDENT_ASSIGNMENT:
      return {
        ...state,
        isUpdating: false,
        lastUpdatedField: action.lastUpdatedField,
        lastUpdatedResult: action.lastUpdatedResult,
      };
    case ACK_UPDATE_TAG:
    case ACK_UPDATE_INCIDENT_STATE:
    case ACK_DELETE_INCIDENT_ASSIGNMENT:
      return {
        ...state,
        isUpdating: false,
        lastUpdatedField: action.lastUpdatedField,
        lastUpdatedResult: action.lastUpdatedResult,
      };
    case REQUEST_REPORT_SUMMARIES_FAILURE:
    case UPDATE_INCIDENT_ASSIGNMENT_FAILURE:
    case UPDATE_TAG_FAILURE:
    case UPDATE_INCIDENT_STATE_FAILURE:
    case DELETE_INCIDENT_ASSIGNMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        error: action.error,
      };
    case TAB_STATUS:
      return {
        ...state,
        tabStatus: action.tab_status,
      };
    default:
      return state;
  }
};

export default reportSummaries;