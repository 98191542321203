export const SET_PROFILE = 'SET_PROFILE'
export const AUTH_CALLED = 'AUTH_CALLED'

export const setProfile = (profile) => {
  return {
    type: SET_PROFILE,
    profile
  }
}

export const authCalled = () => {
  return {
    type: AUTH_CALLED
  }
}
