import React from 'react'
import { connect } from 'react-redux'
import { hideModal, showModal, updateModalProps } from '../../actions/modal'
import { Modal, ListGroup, ListGroupItem, FormGroup, FormControl, InputGroup, Glyphicon } from 'react-bootstrap'

const SelectRoleModal = ({
  agency,
  incident,
  unitId,
  shiftId,
  reportSchemaId,
  roles,
  dispatch,
  visible,
  languageCode
}) => {
  const rolesMaster = agency.assignees.roles
  const hide = () => dispatch(hideModal())

  const saveSelection = (assignmentId) => {
    dispatch(hideModal())

    const startDateModalProps = {
      agency,
      incident,
      unitId,
      shiftId: assignmentId,
      reportSchemaId: incident.report_schema_id,
      assignedRole: incident.role_id,
      roleId: assignmentId,
      languageCode
    }

    dispatch(showModal('SELECT_START_DATE', startDateModalProps))
  }

  const filterList = (event) => {
    var updatedList = rolesMaster
    updatedList = updatedList.filter((item) => {
      return item.name[languageCode].toLowerCase().search(
        event.target.value.toLowerCase()) !== -1
    })

    dispatch(updateModalProps('roles', updatedList))
  }

  return (
    <Modal show={visible} onHide={hide} id='role-modal' dialogClassName='assignment-modal' backdrop>
      <Modal.Header closeButton closeLabel='Cancel'>
        <Modal.Title>Select Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <InputGroup id='role-search-input'>
            <InputGroup.Addon><Glyphicon glyph='search' /></InputGroup.Addon>
            <FormControl id='role-search' type='text' placeholder='Search' onChange={filterList} />
          </InputGroup>
        </FormGroup>
        <ListGroup className='text-medium'>
          {roles.map(role =>
            <ListGroupItem key={role._id} onClick={() => saveSelection(role._id)}>
              <div className='department-name'>{role.name[languageCode]}</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default connect()(SelectRoleModal)
