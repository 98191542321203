import {
  REQUEST_RESPONDER_LOCATIONS,
  RECEIVE_RESPONDER_LOCATIONS,
  REQUEST_RESPONDER_LOCATIONS_FAILURE
} from '../actions/responderLocations'

const responderLocations = (state = {
  isFetching: false,
  items: [],
  lastUpdated: null
}, action) => {
  switch (action.type) {
    case REQUEST_RESPONDER_LOCATIONS:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_RESPONDER_LOCATIONS:
      return {
        isFetching: false,
        items: action.responderLocations,
        lastUpdated: action.receivedAt
      }
    case REQUEST_RESPONDER_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdate: Date.now(),
        error: action.error
      }
    default:
      return state
  }
}

export default responderLocations
