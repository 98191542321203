/* globals localStorage */
export const getToken = () => {
  return localStorage.getItem('id_token')
}

export const getRequestOptions = (token, method = 'GET') => {
  return {
    method: method,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}
