import {
  SET_PROFILE,
  AUTH_CALLED
} from '../actions/profile'

const profile = (state = {
  details: {},
  redirectToReferrer: false,
  authCalled: false
}, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        details: action.profile,
        redirectToReferrer: state.authCalled || false,
        authCalled: false
      }
    case AUTH_CALLED:
      return {
        ...state,
        authCalled: true
      }
    default:
      return state
  }
}

export default profile
