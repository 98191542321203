import { SET_ERROR, CLEAR_ERROR } from '../actions/error'

const defaultState = {
  isActive: false,
  code: null,
  description: null,
  message: null
}

const error = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        isActive: true,
        code: action.code,
        description: action.description,
        message: action.message
      }
    case CLEAR_ERROR:
      return defaultState
    default:
      return state
  }
}

export default error
