import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Map as GMap, Marker, InfoWindow } from 'google-maps-react'
import { IntlProvider, FormattedDate, FormattedTime } from 'react-intl'

class Map extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: null,
      selectedPlace: {},
      map: null
    }

    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.onMapClicked = this.onMapClicked.bind(this)
    this.onMapReady = this.onMapReady.bind(this)
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this)

    //xt took out this line since the google stuff is deprecated..and needs updated for the responder map to work
   // this.pinApiUrl = 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|'
  }

  onMarkerClick (props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    })
  }

  onInfoWindowClose () {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,

    })
  }

  onMapClicked (props) {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }


  onMapReady (mapProps, map) {
    const { agency, languageCode } = this.props

    this.setState({
      map: map
    })

    if (agency) {
      const assignees = agency.details.assignees

      /* const assignees = {
        contract_regions: [
          {
            'color': '#254D13',
            'description': {
              'en_US': 'Bus SFV'
            },
            'location': {
              'latitude': 30.4933508,
              'longitude': -97.5736264,
              'radius_meters': 3851
            },
            'name': {
              'en_US': 'BUS001-SFV'
            }
          },
          {
            'color': '#232325',
            'description': {
              'en_US': 'Bus SFV'
            },
            'location': {
              'latitude': 30.5933508,
              'longitude': -97.5536264,
              'radius_meters': 5349
            },
            'name': {
              'en_US': 'BUS001-SFV'
            }
          }
        ]
      }

      if (assignees.contract_regions) {
        assignees.contract_regions.map(region => {
          const latLng = region.location ? { lat: region.location.latitude, lng: region.location.longitude } : null

          const regionCircle = new window.google.maps.Circle({
            strokeColor: region.color,
            strokeOpacity: 0.2,
            strokeWeight: 2,
            fillColor: region.color,
            fillOpacity: 0.2,
            map: this.state.map,
            center: latLng,
            radius: region.location ? region.location.radius_meters : 0
          })

          const mapLabel = new window.MapLabel({
            text: `${region.name[languageCode]}: ${region.description[languageCode]}`,
            position: new window.google.maps.LatLng(latLng.lat, latLng.lng),
            map: this.state.map,
            fontSize: 14,
            align: 'center'
          })
          mapLabel.set('position', new window.google.maps.LatLng(latLng.lat, latLng.lng))

          return regionCircle || mapLabel
        })
      }*/
    }
  }

  render () {
    const {
      coords,
    } = this.props


    let initialCenter;
    if (coords && coords.coordinates)  {
      initialCenter = coords[0].coordinates;
    }

    return (
      <GMap
        google={window.google}
        onClick={this.onMapClicked}
        zoom={14}
        initialCenter={coords[0].coordinates}
        // initialCenter={coords ? initialCenter : null}
        onReady={this.onMapReady}
      >
        {coords.map((coord, index) => {
          const pinColor = coord ? coord.markerColor : '#B50D0C'
          const pinImage = pinColor ? new window.google.maps.MarkerImage(`${this.pinApiUrl}${pinColor.replace('#', '')}`,
            new window.google.maps.Size(21, 34),
            new window.google.maps.Point(0, 0),
            new window.google.maps.Point(10, 34)) : null

          return (
            <Marker
              key={index}
              onClick={this.onMarkerClick}
              icon={pinImage ? pinImage.url : null}
              title={coord ? coord.title : null}
              name={coord ? coord.name : null}
              received={coord ? coord.received : null}
              position={coord ? coord.coordinates : null}
            />
          )
        }
        )}

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onInfoWindowClose}>
          <div>
            { this.state.selectedPlace.title &&
              <div className='text-medium'>{this.state.selectedPlace.title}</div>
            }
            { this.state.selectedPlace.name &&
              <div className='text-small'>{this.state.selectedPlace.name}</div>
            }
            <div className='history-times text-small'>
              <IntlProvider locale='en'>
                <span>
                  <FormattedDate value={this.state.selectedPlace.received || Date.now()} month='numeric' day='numeric' year='2-digit' />
                  <FormattedTime value={this.state.selectedPlace.received || Date.now()} hour12={false} />
                </span>
              </IntlProvider>
            </div>
          </div>
        </InfoWindow>
      </GMap>
    )
  }
}

Map.propTypes = {
  coords: PropTypes.array.isRequired
}

export default Map
