import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, FormattedDate, FormattedTime } from 'react-intl'
import './IncidentTimes.css'

const IncidentTimes = ({ started, occurred, received, deptAssigned, unitAssigned }) => {
  return (
    <IntlProvider locale='en'>
      <div className='incident-times'>
        {started &&
          <div className='time text-small fix-display'>
            <span>Started</span>
            <FormattedDate value={started} month='numeric' day='numeric' year='2-digit' />
            <FormattedTime value={started} hour12={false} />
          </div>
        }
        {occurred &&
          <div className='time text-small fix-display'>
            <span>Occurred</span>
            <FormattedDate value={occurred} month='numeric' day='numeric' year='2-digit' />
            <FormattedTime value={occurred} hour12={false} />
          </div>
        }
        {received &&
          <div className='time text-small fix-display'>
            <span>Received</span>
            <FormattedDate value={received} month='numeric' day='numeric' year='2-digit' />
            <FormattedTime value={received} hour12={false} />
          </div>
        }
        {deptAssigned &&
          <div className='time text-small fix-display'>
            <span>Dept Assn.</span>
            <FormattedDate value={deptAssigned} month='numeric' day='numeric' year='2-digit' />
            <FormattedTime value={deptAssigned} hour12={false} />
          </div>
        }
        {unitAssigned &&
          <div className='time text-small fix-display'>
            <span>Unit Assn.</span>
            <FormattedDate value={unitAssigned} month='numeric' day='numeric' year='2-digit' />
            <FormattedTime value={unitAssigned} hour12={false} />
          </div>
        }
      </div>
    </IntlProvider>
  )
}

IncidentTimes.propTypes = {
  started: PropTypes.number,
  occurred: PropTypes.number,
  received: PropTypes.number
}

export default IncidentTimes
