import React from 'react'
import PropTypes from 'prop-types'
import { ListGroupItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Agency = ({ agency, languageCode, pathRef }) =>
  <LinkContainer to={`${pathRef}/${agency._id}`}>
    <ListGroupItem>
      {agency.name[languageCode]}
    </ListGroupItem>
  </LinkContainer>

Agency.propTypes = {
  agency: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  pathRef: PropTypes.string.isRequired
}

export default Agency
