import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AuthService from '../../utils/AuthService'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Logo from '../../assets/images/BSGWhiteLOGO.png'
import './Navbar.css'

class TopNav extends React.Component {
  
  permissionsForAgency (agencyId) {
    const { profile } = this.props
    console.log("in navbar.. and this.props is: ", this.props.auth)
    const agencyPermissions = profile.app_metadata.agency_permissions

    if (!agencyPermissions) return false
    const permission = agencyPermissions.find((perm) => {
      return perm.id === '*' || perm.id === agencyId
    })

    if (!permission) return false

    return permission.read_responder_locations ||
      permission.departments.find((perm) => {
        return perm.read_responder_locations
      })
  }

  showResponderTab () {
    const { profile, agency } = this.props
    const appMetadata = profile.app_metadata

    if (!appMetadata) return false
    if (appMetadata.is_admin) return true

    return this.permissionsForAgency(agency.agencyId)
  }
  render () {
    console.log("at navbar render")
    const { profile } = this.props

    return (
      <Navbar id='top-nav'>
        <Navbar.Header>
          <img className='header-image' height='40px' alt='Brass Star Group' src={Logo} />
        </Navbar.Header>
        <Nav>
          <LinkContainer to='/dashboard' className='text-large'>
            <NavItem eventKey={1} href='#'>Incidents</NavItem>
          </LinkContainer>
          { this.showResponderTab() &&
            <LinkContainer to='/responders' className='text-large'>
              <NavItem eventKey={2} href='#'>Responders</NavItem>
            </LinkContainer>
          }
          <LinkContainer to='/reports' className='text-large'>
            <NavItem eventKey={6} href='#'>Reports</NavItem>
          </LinkContainer>
        </Nav>
        { profile.name &&
          <Nav pullRight>
            <NavItem eventKey={3}>
              <img src={profile.picture} className='profilePicture' alt='User profile' />
            </NavItem>
            <NavDropdown className='text-small' eventKey={4} title={profile.user_metadata ? profile.user_metadata.name || profile.name : profile.name} id='logout-dropdown'>
              <LinkContainer to='/logout' className='text-small'>
                <MenuItem eventKey={4.1}>Logout</MenuItem>
              </LinkContainer>
            </NavDropdown>
            <NavItem eventKey={5}>
              <i title='Help' id='help-icon' className='material-icons'>help</i>
            </NavItem>
          </Nav>
        }
      </Navbar>
    )
  }
}

TopNav.propTypes = {
  auth: PropTypes.instanceOf(AuthService)
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.details || {},
    agency: state.agency || {}
  }
}

export default connect(mapStateToProps, null, null, {
  pure: false
})(TopNav)
