import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Services
import LocalizationService from '../../utils/LocalizationService'

// Actions
import { requestStubs, selectAgencyId } from '../../actions/agencyStubs'
import { fetchAgency } from '../../actions/agency'
import { dismissNotification } from '../../actions/reportSummaries'

// Components
import AgencyList from '../Agencies/AgencyList'
import Notification from '../../views/Notification/Notification'

class AgencySelector extends Component {
  constructor (props) {
    super(props)

    this.dismiss = this.dismiss.bind(this)
  }

  componentWillMount () {
    const { agencyStubs, agency, dispatch } = this.props

    if (!agencyStubs.selectedAgencyId && agencyStubs.items.length === 0) {
      dispatch(requestStubs())
    } else if (agencyStubs.selectedAgencyId) {
      if (!agency.details._id) dispatch(fetchAgency(agencyStubs.selectedAgencyId))
    }
  }

  componentWillUpdate (nextProps, nextState) {
    const { agencyStubs, agency, dispatch } = this.props

    if (agencyStubs.selectedAgencyId !== nextProps.agencyStubs.selectedAgencyId) {
      dispatch(fetchAgency(nextProps.agencyStubs.selectedAgencyId))
    } else if (!agency.details && agencyStubs.selectedAgencyId) {
      dispatch(fetchAgency(nextProps.agencyStubs.selectedAgencyId))
    }
  }

  componentWillReceiveProps (nextProps) {
    const { agencyStubs, dispatch, match } = nextProps

    if (!agencyStubs.selectedAgencyId) {
      const agencyId = match.params.id || null

      if (agencyId) {
        dispatch(selectAgencyId(agencyId))
      } else if (agencyStubs.items.length === 1) {
        dispatch(selectAgencyId(agencyStubs.items[0]._id))
      }
    }
  }

  dismiss () {
    this.props.dispatch(dismissNotification())
  }

  render () {
    const {
      agencyStubs,
      pathRef,
      error,
      languageCode
    } = this.props

    if (!agencyStubs.selectedAgencyId) {
      return (
        <AgencyList
          pathRef={pathRef}
          agencies={agencyStubs.items}
          languageCode={languageCode}
        />
      )
    }

    return (
      <div>
        {error.isActive &&
          <Notification text={`Error: ${error.message}`} type='danger' onDismiss={this.dismiss} show={error.isActive} />
        }
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    agencyStubs: state.agencyStubs || [],
    agency: state.agency || {},
    languageCode: LocalizationService.getCurrentLanguage(),
    error: state.error || null
  }
}

AgencySelector.propTypes = {
  pathRef: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(AgencySelector)
