import React, { Component } from 'react'
import { connect } from 'react-redux'
import AgencySelector from '../../components/AgencySelector/AgencySelector'
import Map from '../../components/Map/Map'
import './Responder.css'

// Services
import LocalizationService from '../../utils/LocalizationService'

// Actions
import { fetchResponderLocations } from '../../actions/responderLocations'

class Responders extends Component {
  constructor (props) {
    super(props)

    this.startPoll = this.startPoll.bind(this)
    this.prepareFetch = this.prepareFetch.bind(this)
  }

  componentWillMount () {
    const { agency, responderLocations } = this.props
    console.log("This is Will Mount Agency: ", agency)

    if (agency.details._id && !responderLocations.lastUpdated && responderLocations.items.length === 0) {
      this.prepareFetch(agency)
    }
  }

  componentWillUpdate (nextProps, nextState) {
    const { agency, responderLocations } = nextProps

    if (agency.details._id && !responderLocations.lastUpdated && responderLocations.items.length === 0) {
      this.prepareFetch(agency)
    }
  }

  componentWillReceiveProps (nextProps) {
    const { responderLocations, agency } = nextProps

    if (this.props.responderLocations) {
      if (this.props.responderLocations.items !== responderLocations.items) {
        clearTimeout(this.timeout)

        if (!nextProps.responderLocations.isFetching) {
          this.startPoll(agency)
        }
      }
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  getMarkerTitle (department = {}, unit = {}, shift = {}) {
    const { languageCode } = this.props

    const deptName = department.abbreviation ? department.abbreviation[languageCode] : ''
    const unitName = unit.name ? unit.name[languageCode] : ''
    const shiftName = shift.abbreviation ? shift.abbreviation[languageCode] : ''

    return `${deptName} ${unitName}, ${shiftName}`
  }

  prepareFetch (agency) {
    const { dispatch, responderLocations } = this.props

    if (!responderLocations.isFetching) {
      dispatch(fetchResponderLocations(agency.agencyId))
    } else {
      this.startPoll(agency)
    }
  }

  startPoll (agency) {
    this.timeout = setTimeout(() => {
      this.prepareFetch(agency)
    }, 10000)
  }

  render () {
    const { match, responderLocations, agency, languageCode } = this.props
    let coordinatePoints = []
      console.log("This is the Agency Object: ", agency)
    let agencyMapCenter = agency.details.map_center
    coordinatePoints.push({
        coordinates: {
            lat: agencyMapCenter.latitude,
            lng: agencyMapCenter.longitude
        },
        title: agency.details.name.en_US + " Headquaters",
        markerColor: "#ffffff"
    })

    if (agency.details._id) {
      const assignees = agency.details.assignees || {}

      console.log("This is the Responder Locations Object: ", responderLocations)
      responderLocations.items.forEach((loc) => {
        const department = assignees.departments ? assignees.departments.find(dept => dept._id === loc.department_id) : {}
        const unit = assignees.units ? assignees.units.find(unit => unit._id === loc.unit_id) : {}
        const shift = assignees.shifts ? assignees.shifts.find(shift => shift._id === loc.shift_id) : {}

        coordinatePoints.push({
          coordinates: {
            lat: loc.coordinate.latitude,
            lng: loc.coordinate.longitude
          },
          title: this.getMarkerTitle(department, unit, shift),
          name: department ? department.name[languageCode] : null,
          markerColor: department ? department.color : null,
          received: loc.coordinate.received
        })
      })
    }

    return (
      <AgencySelector pathRef='/responders' match={match} >
        <div id='responder-map-container'>
          <Map coords={coordinatePoints} agency={agency} languageCode={languageCode} />
        </div>
      </AgencySelector>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    agencyStubs: state.agencyStubs || [],
    agency: state.agency || {},
    languageCode: LocalizationService.getCurrentLanguage(),
    responderLocations: state.responderLocations || []
  }
}

export default connect(mapStateToProps)(Responders)
