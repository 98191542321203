/* globals window */
import Globals from './globals'

export const logException = (ex, context) => {
  if (window.Raven) {
    window.Raven.captureException(ex, {
      app_context: context
    })
  }

  window && window.console && console.error && console.error(ex)
}
