import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchInternalMessages, postInternalMessage, updatePendingMessage, clearPendingMessage, clearMessages } from '../../actions/internalMessages'
import { hideModal } from '../../actions/modal'
import { Modal } from 'react-bootstrap'
import Messages from '../../views/Messages/Messages'

class IncidentLogModal extends Component {
  constructor (props) {
    super(props)

    this.addMessage = this.addMessage.bind(this)
    this.prepareFetch = this.prepareFetch.bind(this)
    this.startPoll = this.startPoll.bind(this)
    this.hide = this.hide.bind(this)
  }

  addMessage () {
    const { dispatch, internalMessages, incident, agencyId, reportSchemaId } = this.props

    const message = {
      message: internalMessages.pendingMessage,
      sent: Date.now()
    }

    dispatch(postInternalMessage(incident._id, agencyId, reportSchemaId, message))
    dispatch(clearPendingMessage())
  }

  hide () {
    const { dispatch } = this.props

    dispatch(hideModal())
  }

  prepareFetch () {
    const { dispatch, incident, agencyId, reportSchemaId } = this.props

    dispatch(fetchInternalMessages(incident._id, agencyId, reportSchemaId))
  }

  componentWillMount () {
    this.props.dispatch(clearMessages())
    this.prepareFetch()
  }

  startPoll () {
    this.timeout = setTimeout(() => {
      this.prepareFetch()
    }, 10000)
  }

  componentWillReceiveProps (nextProps) {
    clearTimeout(this.timeout)

    if (!nextProps.internalMessages.isFetching) {
      this.startPoll()
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {
    const {
      incident,
      internalMessages,
      pendingMessage,
      visible
    } = this.props

    return (
      <Modal show={visible} onHide={this.hide} id='log-modal' bsSize='large' backdrop>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='material-icons'>view_headline</i>
            <span className='log-text'>Log</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='log-modal-body'>
          <Messages
            incident={incident}
            messages={internalMessages.messages}
            pendingMessage={pendingMessage}
            clearMessages={clearMessages}
            updatePendingMessage={updatePendingMessage}
            hide={this.hide}
            addMessage={this.addMessage}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    internalMessages: state.internalMessages || []
  }
}

export default connect(mapStateToProps)(IncidentLogModal)
