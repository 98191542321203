import React from 'react'
import { Image, Jumbotron, PageHeader } from 'react-bootstrap'
import BSGStar from '../../assets/images/BSGStar.png'
import './Error.css'

const Error = ({
  code = '404',
  desc = 'Page Not Found',
  message = 'The page you requested cannot be found. Please use the top navigation to browse to a different location'
}) =>
  <div>
    <Jumbotron id='error-jumbotron'>
      <Image src={BSGStar} alt='BSG Star Logo' responsive />
      <PageHeader>{code} {code && '-'} {desc}</PageHeader>
      <p>{message}</p>
    </Jumbotron>
  </div>

export default Error
