import React from 'react'
import { connect } from 'react-redux'
import { hideModal, updateModalProps } from '../../actions/modal'
import { postDepartmentIncidentAssignment } from '../../actions/reportSummaries'
import { Modal, ListGroup, ListGroupItem, FormGroup, FormControl, InputGroup, Glyphicon } from 'react-bootstrap'

const SelectDepartmentModal = ({
  agency,
  incident,
  reportSchemaId,
  departments,
  dispatch,
  visible,
  languageCode
}) => {
  const departmentsMaster = agency.assignees.departments
  const hide = () => dispatch(hideModal())
  const saveSelection = (assignmentId) => {
    if (!incident.assignments[0] || assignmentId !== incident.assignments[0].assignee.department_id) {
      dispatch(postDepartmentIncidentAssignment(incident, agency._id, reportSchemaId, assignmentId))
    }
    dispatch(hideModal())
  }

  const filterList = (event) => {
    var updatedList = departmentsMaster
    updatedList = updatedList.filter((item) => {
      return item.name[languageCode].toLowerCase().search(
        event.target.value.toLowerCase()) !== -1
    })

    dispatch(updateModalProps('departments', updatedList))
  }

  return (
    <Modal show={visible} onHide={hide} id='department-modal' dialogClassName='assignment-modal' backdrop>
      <Modal.Header closeButton>
        <Modal.Title>Select Department</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <InputGroup id='department-search-input'>
            <InputGroup.Addon><Glyphicon glyph='search' /></InputGroup.Addon>
            <FormControl id='department-search' type='text' placeholder='Search' onChange={filterList} />
          </InputGroup>
        </FormGroup>
        <ListGroup className='text-medium'>
          {departments.map(department =>
            <ListGroupItem key={department._id} onClick={() => saveSelection(department._id)}>
              <svg height='40px' width='40px'>
                <circle cx='20' cy='20' r='18' style={{fill: department.color}} />
              </svg>
              <div className='department-name'>{department.name[languageCode]}</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default connect()(SelectDepartmentModal)
