export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const setError = (code, description, message) => {
  return {
    type: SET_ERROR,
    code,
    description,
    message
  }
}

export const clearError = () => {
  return {
    type: CLEAR_ERROR
  }
}
