import {
  SHOW_MODAL,
  HIDE_MODAL,
  UPDATE_MODAL_PROPS
} from '../actions/modal'

const initialState = {
  modalType: null,
  modalProps: {},
  visible: false
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
        visible: true
      }
    case HIDE_MODAL:
      return initialState
    case UPDATE_MODAL_PROPS:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          [action.field]: action.props
        }
      }
    default:
      return state
  }
}

export default modal
