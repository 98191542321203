import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route {...rest} render={props => (
    auth.loggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )
  )} />
)

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
  auth: PropTypes.object.isRequired
}

export default PrivateRoute
