import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";
import "./StateTabs.css";

const StateTabs = ({
  toggleStateVisibility,
  agency,
  languageCode,
  tabStatusDetails,
}) => {
  if (!agency.details.display_pages.display_statuses) return <div />;
  if (agency.details.display_pages.display_statuses.length === 0)
    return <div />;

  const statuses = agency.details.display_pages.display_statuses;
  console.log("in stateTabs at statuses: ", statuses[2]);
  var statusesTab = statuses.slice(1);
  console.log("statusesTab is: ", statusesTab);

  return (
    <div>
      <Tabs
        defaultActiveKey={
          tabStatusDetails ? tabStatusDetails : "SHOW_New & Open"
        }
        onSelect={toggleStateVisibility}
        id="state_visibility_tabs"
      >
        <Tab eventKey={"SHOW_New & Open"} title="New and Open" />
        {statusesTab.map((status, index) => {
          return (
            <Tab
              key={index}
              eventKey={`SHOW_${status.title[languageCode]}`}
              title={status.title[languageCode]}
            />
          );
        })}
        <Tab eventKey={"SHOW_CHATTING"} title="Chatting" />
      </Tabs>
    </div>
  );
};

StateTabs.propTypes = {
  toggleStateVisibility: PropTypes.func.isRequired,
};

export default StateTabs;
