import { createSelector } from 'reselect'
import LocalizationService from '../utils/LocalizationService'

const languageCode = LocalizationService.getCurrentLanguage()
const getVisibilityFilter = state => state.report.visibilityFilter
const getReportSummaries = state => state.report.reports
const getAgency = state => state.agency.details
const getReportSchema = (agency, summary) => agency.report_schemas.find(schema => schema._id === summary.report_schema_id)
const getAgencyStatusMap = (agency, name) => agency.display_pages
  ? agency.display_pages.display_statuses.find(status => status.title[languageCode] === name)
  : []

const filterReportsByStatus = (report, agency, filter) => {
  const statusName = filter.substring(filter.indexOf('_') + 1)
  const statusMap = getAgencyStatusMap(agency, statusName)

  return report.items.filter(summary => {
    let statusId = summary.status_id

    if (!statusId) { // Get the status for the current state
      const schema = getReportSchema(agency, summary)
      const state = schema.report_states.find(state => state._id === summary.state)
      statusId = state.report_status_id
    }

    return statusMap ? statusMap.report_statuses.indexOf(statusId) > -1 : false
  })
}

export const getVisibleReportSummaries = createSelector(
  [getVisibilityFilter, getReportSummaries, getAgency],
  (visibilityFilter, report, agency) => {
    // console.log('reportsum:', report);

    switch (visibilityFilter) {
      case 'SHOW_CHATTING':
      case 'SHOW_ALL':
        return report
        
      default:
        return {
          ...report,
          items: filterReportsByStatus(report, agency, visibilityFilter),
          reports: filterReportsByStatus(report, agency, visibilityFilter),
        }
    }
  }
)
