import {
  REQUEST_AGENCY_STUBS,
  RECEIVE_AGENCY_STUBS,
  SELECT_AGENCY_ID,
  INVALIDATE_AGENCY,
  REQUEST_AGENCY_STUBS_FAILURE
} from '../actions/agencyStubs'

const getAgencyId = () => {
  return window.localStorage
    ? window.localStorage.getItem('selected_agency') || null
    : null
}

const clearAgencyId = () => {
  if (window.localStorage) window.localStorage.removeItem('selected_agency')
}

const saveAgencyId = (agencyId) => {
  if (window.localStorage) window.localStorage.setItem('selected_agency', agencyId)
}

const agencyStubs = (state = {
  isFetching: false,
  didInvalidate: false,
  selectedAgencyId: getAgencyId(),
  items: []
}, action) => {
  switch (action.type) {
    case REQUEST_AGENCY_STUBS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_AGENCY_STUBS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.agencyStubs,
        lastUpdated: action.receivedAt
      })
    case REQUEST_AGENCY_STUBS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case INVALIDATE_AGENCY:
      clearAgencyId()
      return Object.assign({}, state, {
        didInvalidate: true,
        selectedAgencyId: action.agencyId
      })
    case SELECT_AGENCY_ID:
      saveAgencyId(action.agencyId)
      return Object.assign({}, state, {
        didInvalidate: false,
        selectedAgencyId: action.agencyId
      })
    default:
      return state
  }
}

export default agencyStubs
