import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import AuthService from './utils/AuthService';
import Globals from './utils/globals';
import store, { history } from './store';
import Beforeunload from 'react-beforeunload';

import App from './components/App/App';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const auth = new AuthService(Globals.auth0Client, Globals.auth0Domain);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {/* <Beforeunload
        onBeforeunload={() => {
          return auth.logout();
        }}
      > */}
        <App />
      {/* </Beforeunload> */}
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
