import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMessages, postChatMessage, updatePendingMessage, clearPendingMessage, clearMessages } from '../../actions/messages'
import { Modal } from 'react-bootstrap'
import Messages from '../../views/Messages/Messages'


class FullscreenIncidentChatModal extends Component {
  constructor (props) {
    super(props)

    this.addMessage = this.addMessage.bind(this)
    this.prepareFetch = this.prepareFetch.bind(this)
    this.startPoll = this.startPoll.bind(this)
  }

  addMessage () {
    const { dispatch, messages, profile, incident, agencyId, reportSchemaId } = this.props

    const message = {
      type: 'chat',
      display_name: profile.user_metadata ? profile.user_metadata.name : profile.name,
      message: messages.pendingMessage,
      sender_id: profile.user_id,
      sent: Date.now()
    }

    dispatch(postChatMessage(incident._id, agencyId, reportSchemaId, message))
    dispatch(clearPendingMessage())
  }


  prepareFetch () {
    const { dispatch, incident, agencyId, reportSchemaId } = this.props

    dispatch(fetchMessages(incident._id, agencyId, reportSchemaId))
  }



  componentWillMount () {
    this.props.dispatch(clearMessages())
    this.prepareFetch()
  }


  startPoll () {
    this.timeout = setTimeout(() => {
      this.prepareFetch()
    }, 10000)
  }

  componentWillReceiveProps (nextProps) {
    clearTimeout(this.timeout)

    if (!nextProps.messages.isFetching) {
      this.startPoll()
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {
    const {
      incident,
      messages,
      pendingMessage,
      visible
    } = this.props

    return (
      <Modal show={visible} id='chat-modal' bsSize='large'>
        <Modal.Header>
          <Modal.Title>
            <i className='material-icons'>chat</i>
            <span className='image-text'>Chat</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body  id='chat-modal-body'>
          <Messages
            closeTitle='End Chat'
            incident={incident}
            messages={messages.messages.filter((message) => message.type === 'chat')}
            pendingMessage={pendingMessage}
            clearMessages={clearMessages}
            updatePendingMessage={updatePendingMessage}
            addMessage={this.addMessage}
            closeChat={this.closeChat}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages || [],
    profile: state.profile.details || { user_id: '' }
  }
}

export default connect(mapStateToProps)(FullscreenIncidentChatModal)
